import Vue from 'vue'
import Toasted from 'vue-toasted'
import feather from 'feather-icons'

const featherIcon = (type) => feather.icons[type].toSvg()

const toastOptions = {
  theme: 'toasted-primary',
  position: 'bottom-left',
  duration: 5000,
}

Vue.use(Toasted, toastOptions)

const setIcon = (customIcon, defaultIcon) => (
  customIcon ? featherIcon(customIcon) : featherIcon(defaultIcon)
)

Vue.toasted.register('error', (payload) => {
  const icon = setIcon(payload.icon, 'alert-triangle')

  if (payload.message) {
    return `
      ${icon}
      <div>
        <p>${payload.title}</p>
        <small>${payload.message}</small>
      </div>
    `
  }

  return `${icon} <p>${payload.title}</p>`
})

Vue.toasted.register('success', (payload) => {
  const icon = setIcon(payload.icon, 'check')

  return `${icon} <p>${payload.title}</p>`
})

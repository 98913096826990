import actions from './actions'
import mutations from './mutations'

const state = {
  magnaMission: {
    loading: false,
    data: null,
    error: null,
  },
  currentEnigmaPosition: null,
  selectedSasQuestions: [],
}

export default {
  state,
  mutations,
  actions,
}

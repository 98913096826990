export const featureToggles = [
  {
    toggleName: 'eureka_retro',
    storeName: 'eurekaRetrospective',
    defaultValue: false,
    userData: 'userId',
  },
  {
    toggleName: 'eureka_arena_onboarding',
    storeName: 'eurekaArenaOnboarding',
    defaultValue: false,
    userData: 'userId',
  },
  {
    toggleName: 'eureka_timer',
    storeName: 'eurekaTimer',
    defaultValue: false,
    userData: 'schoolId',
  },
  {
    toggleName: 'eureka_challenge_ranking',
    storeName: 'eurekaChallengeRanking',
    defaultValue: false,
    userData: 'schoolId',
  },
  {
    toggleName: 'eureka_home_missions',
    storeName: 'eurekaHomeMissions',
    defaultValue: false,
    userData: 'schoolId',
  },
  {
    toggleName: 'eureka_sas_questions',
    storeName: 'eurekaSasQuestions',
    defaultValue: false,
    userData: 'schoolId',
  },
  {
    toggleName: 'eureka_active_gtm_debug',
    storeName: 'eurekaActiveGtmDebug',
    defaultValue: false,
    userData: 'schoolId',
  },
]

export const featureTogglesInitialState = Object.fromEntries(
  featureToggles.map(({ storeName, defaultValue }) => [[ storeName ], defaultValue ])
)

import academicRecordsService from '@/service/academicRecords'
import * as types from './types'

export default {
  getAcademicRecords: async ({ commit }) => {
    commit(types.SET_ACADEMIC_RECORD, { loading: true })

    try {
      const { data } = await academicRecordsService.getAcademicRecords()

      commit(types.SET_ACADEMIC_RECORD, {
        data,
        loading: false,
        error: null,
        skeletonLoadingCount: data.length,
      })
    } catch (error) {
      commit(types.SET_ACADEMIC_RECORD, {
        loading: false,
        data: [],
        error,
      })
    }
  },
}

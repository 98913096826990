import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  academicRecords: {
    loading: false,
    data: [],
    error: null,
    skeletonLoadingCount: 4,
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

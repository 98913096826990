import * as types from './types'

export default {
  [types.SET_SEASONS_LIST](state, seasons) {
    state.seasons = {
      ...state.seasons,
      ...seasons,
    }
  },
  [types.SET_HISTORY_EPISODE](state, episode) {
    state.historyEpisode = {
      ...state.historyEpisode,
      ...episode,
    }
  },
  [types.SET_EPISODE_SCENE](state, scene) {
    state.episodeScene = {
      ...scene,
    }
  },
  [types.CLEAR_HISTORY_EPISODE](state) {
    state.historyEpisode = {
      data: {},
    }
  },
}

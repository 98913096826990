/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import themes from '@/themes'
import { gradeTemplates } from '@/themes/grades'
import * as types from './types'
import {
  iterateImageSide,
  groundObject,
  skyObject,
  lectures
} from './utils'

export default {
  getTheme: async ({ commit }, lectureId) => {
    commit(types.SET_THEME, { loading: true })

    try {
      const theme = await themes(lectureId)
      commit(types.SET_THEME, { data: theme })
    } catch (error) {
      commit(types.SET_THEME, { error: true })
    } finally {
      commit(types.SET_THEME, { loading: false })
    }
  },
  setThemes: async ({ commit }, { worldId, gradeTemplateId }) => {
    const { lecture } = lectures.find(({ id }) => id === parseInt(worldId, 10))

    const { templates } = gradeTemplates.find(({ name }) => name === lecture)
    const template = templates.find(({ gradeId }) => gradeId === gradeTemplateId)
    const { gradeTemplate, imageAmount } = template

    const leftSide = iterateImageSide(imageAmount.left, 'left')
    const rightSide = iterateImageSide(imageAmount.right, 'right')

    const { background: skyBackground, color: skyColor } = skyObject(lecture, gradeTemplateId)
    const {
      background: groundBackground,
      color: groundColor,
    } = groundObject(lecture, gradeTemplateId)

    const theme = {
      slug: lecture,
      worldId,
      sky: {
        background: skyBackground,
        color: skyColor,
      },
      ground: {
        background: groundBackground,
        color: groundColor,
        darkMode: false,
        trackColor: 'rgb(255, 255, 255, 0.2)',
      },
      planet: require(`@/themes/${lecture}/${gradeTemplate}/world.svg`),
      missions: {
        top: {
          sky: require(`@/themes/${lecture}/${gradeTemplate}/sky.svg`),
        },
        middle: {
          left: leftSide.map((image) => require(`@/themes/${lecture}/${gradeTemplate}/${image}.svg`)),
          right: rightSide.map((image) => require(`@/themes/${lecture}/${gradeTemplate}/${image}.svg`)),
        },
      },
      mission: {
        background: require(`@/themes/${lecture}/${gradeTemplate}/mission-bg.svg`),
      },
    }
    commit(types.SET_THEMES, theme)
  },
  resetThemes: async ({ commit }) => {
    commit(types.SET_THEMES)
  },
}

import { success, done } from '@/store/utils'

export default {
  assignments: (state) => ({
    ...state.assignments,
    success: success(state.assignments),
    done: done(state.assignments),
  }),
  assignment: (state) => ({
    ...state.assignment,
    success: success(state.assignment),
    done: done(state.assignment),
  }),
  report: (state) => ({
    ...state.report,
    success: success(state.report),
    done: done(state.report),
  }),
  redo: (state) => ({
    ...state.redo,
    success: success(state.redo),
    done: done(state.redo),
  }),
}

import * as types from './types'

export default {
  [types.SET_ALL_NEWS](state, news) {
    state.allNews = {
      ...state.allNews,
      ...news,
    }
  },
  [types.SET_NOVELTY](state, novelty) {
    state.novelty = {
      ...state.novelty,
      ...novelty,
    }
  },
  [types.CLEAR_NOVELTIES](state) {
    state.allNews = {
      loading: false,
      data: [],
      error: null,
    }
    state.novelty = {
      loading: false,
      data: {},
      error: null,
    }
  },
  [types.SET_UNREAD_NEWS](state, unreadNews) {
    state.unreadNews = {
      ...state.unreadNews,
      ...unreadNews,
    }
  },
  [types.SET_IS_TO_OPEN_NEWS_DRAWER](state, isToOpenNewsDrawer) {
    state.isToOpenNewsDrawer = isToOpenNewsDrawer
  },
}

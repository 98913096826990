import { apiBff } from '@/service/apiBff'

export const PROFILES = {
  STUDENT: 2,
  TEACHER: 3,
  SCHOOL: 7,
  MANAGER: 17,
  CONSULTANT: 5,
}

export const STAGES = {
  9: 'HIGH_SCHOOL',
  10: 'MIDDLE_SCHOOL',
  11: 'MIDDLE_SCHOOL',
  MIDDLE_SCHOOL: 'middle_school',
  HIGH_SCHOOL: 'high_school',
}

export const getInsignias = async (params) => (
  apiBff.get('/insignias', { params })
)

export const containsUserProfile = (profiles, profileId) => (
  profiles?.some((profile) => profile.id === profileId)
)

export const userTypes = (profiles, stage) => {
  const isMiddleSchoolStudent = (
    containsUserProfile(profiles, PROFILES.STUDENT)
    && stage === STAGES.MIDDLE_SCHOOL
  )
  const isHighSchoolStudent = (
    containsUserProfile(profiles, PROFILES.STUDENT)
    && stage === STAGES.HIGH_SCHOOL
  )

  const isTeacher = containsUserProfile(profiles, PROFILES.TEACHER)
  const isSchool = containsUserProfile(profiles, PROFILES.SCHOOL)
  const isManager = containsUserProfile(profiles, PROFILES.MANAGER)

  const userIsStudent = isMiddleSchoolStudent || isHighSchoolStudent

  return ({
    isMiddleSchoolStudent,
    isHighSchoolStudent,
    isTeacher,
    isSchool,
    isManager,
    userIsStudent,
  })
}

import * as types from './types'

export default {
  [types.SET_SOUND_PREFERENCES](state, preferences) {
    state.soundPreferences = {
      ...state.soundPreferences,
      ...preferences,
    }
  },
  [types.SET_ALL_PREFERENCES](state, preferences) {
    state.allPreferences = {
      ...state.allPreferences,
      ...preferences,
    }
  },
  [types.SET_ALREADY_PLAYED](state, sceneId) {
    state.scenesAlreadyPlayedEffects.data.push(sceneId)
  },
  [types.CLEAR_ALREADY_PLAYED](state) {
    state.scenesAlreadyPlayedEffects.data = []
  },
}

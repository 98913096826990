import { success, done } from '@/store/utils'
import { getAuthToken } from '@/utils/auth'

export default {
  user: (state) => state.user,
  userGameData: (state) => ({
    ...state.gameData,
    success: success(state.gameData),
    done: done(state.gameData),
  }),
  userStats: (state) => ({
    ...state.userStats,
    success: success(state.userStats),
    done: done(state.userStats),
  }),
  userGrades: (state) => ({
    ...state.userGrades,
  }),
  token: () => getAuthToken(),
}

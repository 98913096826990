import * as types from './types'

export default {
  [types.SET_USER](state, user) {
    state.user = user
  },
  [types.SET_GAME_DATA](state, gameData) {
    state.gameData = {
      ...state.gameData,
      ...gameData,
    }
  },
  [types.SET_USER_STATS](state, userStats) {
    state.userStats = {
      ...state.userStats,
      ...userStats,
    }
  },
  [types.SET_USER_GRADES](state, userGrades) {
    state.userGrades = {
      ...state.userGrades,
      ...userGrades,
    }
  },
  [types.SET_TOKEN](state, { token }) {
    state.token = token
  },
  [types.CLEAR_ALL](state) {
    state.user = {
      name: '',
    }
    state.gameData = {
      data: {},
      loading: false,
      error: null,
    }
    state.token = null
  },
  [types.REALLOCATION_DATE_LIMIT](state, payload) {
    state.reallocationDateLimit = {
      ...state.reallocationDateLimit,
      ...payload,
    }
  },
}

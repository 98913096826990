<template>
  <div class="global-toast">
    <GToast
      v-show="toast.isOpen"
      :text="toast.text"
      :description="toast.description"
      :variation="toast.variation"
      :asset="toast.asset"
      :auto-close="toast.autoClose"
      @close="close"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GToast from '@/components/GToast'

export default {
  name: 'GlobalToast',
  components: {
    GToast,
  },
  computed: {
    ...mapState('globalToast', [ 'toast' ]),
  },
  methods: {
    ...mapActions('globalToast', [ 'closeToast' ]),
    close() {
      this.closeToast()
    },
  },
}
</script>

<style lang="scss" scoped>
.global-toast {
  ::v-deep .g-toast {
    z-index: 999999;
  }
}
</style>

import { gradeTemplates } from '@/themes/grades'

const lectures = [
  { lecture: 'science', id: 1153 },
  { lecture: 'portuguese', id: 1129 },
  { lecture: 'math', id: 972 },
  { lecture: 'physics', id: 1110 },
  { lecture: 'chemistry', id: 1111 },
  { lecture: 'biology', id: 1123 },
  { lecture: 'geography', id: 1115 },
  { lecture: 'history', id: 1114 },
]

const iterateImageSide = (amount, sideType) => {
  const images = []
  for (let index = 0; index < amount; index += 1) {
    images.push(`${sideType}-${index}`)
  }

  return images
}

const groundObject = (lecture, id) => {
  const gradesId = gradeTemplates
  const { templates } = gradesId.find(({ name }) => name === lecture)
  const { ground } = templates.find(({ gradeId }) => gradeId === id)

  return ground
}

const skyObject = (lecture, id) => {
  const gradesId = gradeTemplates
  const { templates } = gradesId.find(({ name }) => name === lecture)
  const { sky } = templates.find(({ gradeId }) => gradeId === id)

  return sky
}

export {
  iterateImageSide,
  groundObject,
  skyObject,
  lectures,
}

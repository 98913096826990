import userPreferencesApi from '@/service/userPreferences'
import getGradeId from '@/utils/getGradeId'
import * as types from './types'

const defaultPreferences = {
  sound: true,
  music: true,
}

export default {
  getSoundPreferences: async ({ commit, rootState }) => {
    const { allPreferences } = rootState.userPreferences

    commit(types.SET_SOUND_PREFERENCES, {
      data: allPreferences.data || defaultPreferences,
    })
  },
  setSoundPreferences: ({ commit, rootState, dispatch }, preferences) => {
    const { allPreferences } = rootState.userPreferences

    commit(types.SET_SOUND_PREFERENCES, {
      data: {
        ...allPreferences.data || defaultPreferences,
        ...preferences,
      },
    })

    dispatch('setUserPreferences', preferences)
  },
  getUserPreferences: async ({ commit, rootState }) => {
    const { allPreferences } = rootState.userPreferences
    const defaultUserPreferences = {
      music: true,
      nightMode: false,
      sound: true,
      worldsLayoutGrid: false,
      logosTheme: false,
    }

    commit(types.SET_ALL_PREFERENCES, {
      loading: true,
    })

    try {
      const response = await userPreferencesApi.getUserPreferences()

      commit(types.SET_ALL_PREFERENCES, {
        loading: false,
        data: response.data,
        error: null,
        ready: true,
      })
    } catch (error) {
      if (allPreferences.data) {
        commit(types.SET_ALL_PREFERENCES, {
          loading: false,
          data: allPreferences.data,
          error: null,
          ready: true,
        })
      } else {
        commit(types.SET_ALL_PREFERENCES, {
          loading: false,
          data: defaultUserPreferences,
          error,
          ready: true,
        })
      }
    }
  },
  setUserPreferences: async ({ commit, rootState, getters }, preferences) => {
    const { allPreferences } = rootState.userPreferences
    const oldPreferences = { ...allPreferences.data }
    const newPreferences = {
      ...oldPreferences,
      ...preferences,
    }

    let body = Object.entries(preferences).map(([ key, value ]) => ({
      name: key,
      active: value,
    }))

    commit(types.SET_ALL_PREFERENCES, {
      loading: true,
      error: null,
    })

    try {
      body = {
        preferences: [ ...body ],
        gradeId: getGradeId(getters),
      }

      await userPreferencesApi.updateUserPreferences(body)

      commit(types.SET_ALL_PREFERENCES, {
        loading: false,
        data: newPreferences,
        error: null,
      })
    } catch (error) {
      commit(types.SET_ALL_PREFERENCES, {
        loading: false,
        data: oldPreferences,
        error: null,
      })
    }
  },
  setUserGameIcon: async ({ commit, rootState, getters }, { id, image }) => {
    const { allPreferences } = rootState.userPreferences
    const oldPreferences = { ...allPreferences.data }
    const gameIcon = {
      id,
      image,
    }
    const newPreferences = {
      ...oldPreferences,
      gameIcon,
    }
    const body = {
      gradeId: getGradeId(getters),
      preferences: [{
        name: 'gameIcon',
        active: true,
        value: id,
      }],
    }

    commit(types.SET_ALL_PREFERENCES, {
      loading: true,
      error: null,
    })

    try {
      await userPreferencesApi.updateUserPreferences(body)

      commit(types.SET_ALL_PREFERENCES, {
        loading: false,
        data: newPreferences,
        error: null,
      })
    } catch (error) {
      commit(types.SET_ALL_PREFERENCES, {
        loading: false,
        data: oldPreferences,
        error: true,
      })
    }
  },
  setScenesAlreadyPlayedEffects: ({ commit }, sceneId) => {
    commit(types.SET_ALREADY_PLAYED, sceneId)
  },
  clearScenesAlreadyPlayedEffects: ({ commit }) => {
    commit(types.CLEAR_ALREADY_PLAYED)
  },
}

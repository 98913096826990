/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

  return {
    width: Math.round(srcWidth * ratio),
    height: Math.round(srcHeight * ratio),
  }
}

export const createImage = (src) => new Promise((resolve, reject) => {
  const img = new Image()

  img.onload = () => {
    resolve(img)
  }
  img.onerror = reject
  img.src = src
})

const getFileExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf('.')

  return lastDotIndex !== -1 ? filename.slice(lastDotIndex + 1) : ''
}

const getImageTypeFromExtension = (extension) => {
  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    case 'gif':
      return 'image/gif'
    case 'webp':
      return 'image/webp'
    default:
      return null
  }
}

export const reduceImage = async ({ src, maxSize }) => {
  const resizer = (await import('pica')).default()
  const img = await createImage(src)
  const offScreenCanvas = document.createElement('canvas')

  if (img.width < maxSize && img.height < maxSize) {
    offScreenCanvas.width = img.width
    offScreenCanvas.height = img.height
  } else if (img.width < img.height || img.width > img.height) {
    const { width, height } = calculateAspectRatioFit(img.width, img.height, maxSize, maxSize)

    offScreenCanvas.width = width
    offScreenCanvas.height = height
  } else {
    offScreenCanvas.width = maxSize
    offScreenCanvas.height = maxSize
  }

  const result = await resizer.resize(img, offScreenCanvas, {
    quality: 3,
    unsharpAmount: 80,
    unsharpRadius: 0.6,
    unsharpThreshold: 2,
    alpha: false,
    transferable: true,
  })

  const extension = getFileExtension(src)
  const imageType = getImageTypeFromExtension(extension)

  if (imageType === 'image/webp') {
    // keeps webp because is better
    return resizer.toBlob(result, imageType)
  }

  // converts to jpg image type
  const JPG_QUALITY = 0.80

  return resizer.toBlob(result, 'image/jpeg', JPG_QUALITY)
}

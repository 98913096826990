import * as types from './types'

export default {
  showToast({ commit }, payload) {
    commit(types.SET_TOAST, { ...payload, isOpen: true })
  },
  closeToast({ commit }) {
    commit(types.SET_TOAST_INITIAL)
  },
}

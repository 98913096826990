import api from '@/service/api'
import * as types from './types'

export default {
  setMagnaMission: ({ commit }, { loading = false, data = null, error = null }) => {
    commit(types.SET_MAGNA_MISSION, { data, error, loading })
  },
  createMagnaMission: async ({ dispatch }, { questionnaireCode, classroomId, isRanked }) => {
    dispatch('setMagnaMission', { loading: true })

    try {
      const { data } = await api.post('challenge-questionnaires', {
        questionnaireCode,
        classroomId,
        isRanked,
      })

      dispatch('setMagnaMission', { data })
    } catch (error) {
      dispatch('setMagnaMission', { error })
      Promise.reject(error)
    }
  },
  setEnigmaPosition: ({ commit }, position) => {
    commit(types.SET_CURRENT_ENIGMA_POSITION, position)
  },
  setSelectedSasQuestions: ({ commit }, questions) => {
    commit(types.SET_SELECTED_SAS_QUESTIONS, questions)
  },
}

import { apiBff } from '@/service/apiBff'
import * as types from './types'

export default {
  getAllNews({ commit }) {
    commit(types.CLEAR_NOVELTIES)
    commit(types.SET_ALL_NEWS, { loading: true })

    return apiBff.get('/news')
      .then((response) => {
        commit(types.SET_ALL_NEWS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_ALL_NEWS, {
          error: error.response || true,
          loading: false,
        })
      })
  },
  getNovelty({ commit }, noveltyId) {
    commit(types.CLEAR_NOVELTIES)
    commit(types.SET_NOVELTY, { loading: true })

    return apiBff.get(`/news/${noveltyId}`)
      .then((response) => {
        commit(types.SET_NOVELTY, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_NOVELTY, {
          error: error.response || true,
          loading: false,
        })
      })
  },
  clearNoveltyState({ commit }) {
    commit(types.CLEAR_NOVELTIES)
  },
  getUnreadNews: async ({ commit }) => {
    commit(types.SET_UNREAD_NEWS, { loading: true })

    return apiBff.get('/news/unread-amount')
      .then(({ data }) => {
        commit(types.SET_UNREAD_NEWS, {
          data,
          loading: false,
        })
      })
      .catch(({ response }) => {
        commit(types.SET_UNREAD_NEWS, {
          error: response || true,
          loading: false,
        })
      })
  },
  setIsToOpenNewsDrawer: async ({ commit }, isToOpenNewsDrawer) => {
    commit(types.SET_IS_TO_OPEN_NEWS_DRAWER, isToOpenNewsDrawer)
  },
}

import storage from '@/utils/storage'
import * as types from './types'
import { TOUR_VERSION } from './utils/constants'

export default {
  [types.SET_ONBOARDING](state, { onboarding }) {
    storage.setItem(TOUR_VERSION, onboarding)
    state.onboarding = onboarding
  },
}

<template>
  <div id="eurekaapp">
    <router-view />
    <GlobalToast />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import accountApi from '@/service/account'
import typeform from '@/service/typeform'
import userIsStudent from '@/mixins/userIsStudent'
import gradeId from '@/mixins/gradeId'
import GlobalToast from '@/components/GlobalToast/GlobalToast'

export default {
  components: {
    GlobalToast,
  },
  mixins: [ userIsStudent, gradeId ],
  props: {
    mfe: {
      type: Boolean,
      default: false,
    },
    basename: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'user',
      'rewards',
      'soundPreferences',
      'userPreferences',
    ]),
    ...mapState({
      eurekaActiveGtmDebug: ({ featureToggles }) => featureToggles
        .toggles.eurekaActiveGtmDebug,
    }),
  },
  watch: {
    async user() {
      if (this.user.data) {
        if (JSON.parse(process.env.VUE_APP_TRACKING_ENABLED)) {
          this.pushUserToDataLayer()
        }
      }
    },
    rewards() {
      this.fetchUserGameData()
    },
    eurekaActiveGtmDebug: {
      immediate: true,
      handler(value) {
        this.$gtm.debug(value ?? false)
      },
    },
  },
  async created() {
    await this.getUser()
    await this.fetchUserGameData()
    await this.getUserPreferences()

    this.setSoundPreferences({
      sound: this.userPreferences.data?.sound,
      music: this.userPreferences.data?.music,
    })

    typeform.install(this.setSurveyButtonVisible(true))
  },
  methods: {
    ...mapActions([
      'getUser',
      'getFeatureToggles',
      'getUserGameData',
      'getUserPreferences',
      'setSoundPreferences',
    ]),
    ...mapActions('typeform', [ 'setTypeformReady' ]),
    async pushUserToDataLayer() {
      const {
        id: accountId,
        school,
        profiles,
        grades,
      } = this.user.data

      const { data: personalData } = await accountApi.getPersonalData()

      window.dataLayer.push({
        accountId,
        schoolId: school.id,
        profileId: profiles[0].id,
        gradeId: grades.map(({ id }) => id),
        prospection: personalData.schoolProspection,
      })
    },
    fetchUserGameData() {
      let params

      if (!this.userIsStudent) {
        params = this.gradeId
      }

      this.getUserGameData(params)
    },
    setSurveyButtonVisible(value) {
      this.setTypeformReady(value)
    },
  },
}
</script>
<style src="@/styles/main.sass" lang="sass">

import store from '@/store'
import accountApi from '@/service/account'

export default async function isStudent() {
  const { student: userIsStudent } = store.state.profile.personalData
    ?? { student: false }

  if (userIsStudent) {
    return true
  }

  try {
    const { data: personalData } = await accountApi.getPersonalData()

    return personalData.student
  } catch (error) {
    return false
  }
}

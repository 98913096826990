const success = (state) => {
  let hasData = false
  if (typeof state === 'object' && state !== null && state.data) {
    if (Array.isArray(state.data)) {
      hasData = true
    } else {
      hasData = Object.values(state.data).length > 0
    }
  } else if (typeof state.data === 'string') {
    hasData = state.data.length > 0
  } else if (typeof state.data === 'number') {
    hasData = true
  }

  return hasData
    && state.error === null
    && !state.loading
}
const done = ({ data, loading }) => (
  data !== null
  && !loading
)

const getContentYear = (currentYear, contractType, userGrades, gradeId) => {
  const [ currentGrade ] = userGrades.filter((grade) => grade.id === gradeId)
  const hasEurekaContentYear = currentGrade && currentGrade.eurekaContentYear

  if (contractType === 'ADAPT' && hasEurekaContentYear) {
    return currentGrade.eurekaContentYear
  }

  return currentYear
}

export {
  success,
  done,
  getContentYear,
}

import * as types from './types'

export default {
  [types.SET_LECTURES](state, lectures) {
    state.lectures = {
      ...state.lectures,
      ...lectures,
    }
  },
  [types.SET_LECTURE](state, lecture) {
    state.lecture = {
      ...state.lecture,
      ...lecture,
    }
  },
  [types.SET_NEXT_LECTURE](state, lecture) {
    state.nextLecture = {
      ...state.nextLecture,
      ...lecture,
    }
  },
}

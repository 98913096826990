import accountApi from '@/service/account'
import store from '@/store'
import { deleteCookie } from '@sas-te/frontend-utils/modules/cookies'
import { userTypes } from '@/service/profiles'
import httpCodes from '@/enums/httpCodes'
import { getPortalURL } from '@/utils/urls'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'
import * as types from './types'

const isMicrofrontend = getIsMicrofrontend()
const portalURL = getPortalURL(isMicrofrontend)

export default {
  getUser: async (
    { dispatch, commit },
    token = store.getters.token || null
  ) => {
    if (token) {
      commit(types.SET_USER, { loading: true, data: null, error: null })
      try {
        const [
          userResponse,
          signinResponse,
        ] = await Promise.all([
          accountApi.getUser(),
          accountApi.getSignedIn(),
        ])

        const user = userResponse.data
        const stage = signinResponse.data
        const userProfile = userTypes(user.profiles, stage)

        await dispatch('getUserGrades')
        await dispatch('getReallocationDateLimit')

        commit(types.SET_USER, {
          loading: false,
          data: {
            ...user,
            stage,
            ...userProfile,
          },
        })

        await dispatch('getFeatureToggles')

        return user
      } catch (error) {
        commit(types.SET_USER, {
          loading: false,
          error: error.response || true,
        })
        console.error(error.response)
        if (error.response?.status === httpCodes.forbidden) {
          window.location.assign(`${portalURL}`)
        }
      }
    }

    return false
  },
  getUserGrades: async ({ commit }) => {
    commit(types.SET_USER_GRADES, { loading: true })
    try {
      const gradesResponse = await accountApi.getUserGrades()
      const userGrades = await gradesResponse.data

      commit(types.SET_USER_GRADES, {
        loading: false,
        data: userGrades,
      })
    } catch (error) {
      commit(types.SET_USER_GRADES, {
        loading: false,
        error,
      })
    }
  },
  getUserGameData: async ({ commit }, gradeId) => {
    commit(types.SET_GAME_DATA, { loading: true, error: null })

    const params = {}

    if (gradeId) {
      params.gradeId = gradeId
    }

    try {
      const response = await accountApi.getUserGameData(params)
      commit(types.SET_GAME_DATA, { data: response.data })
    } catch (error) {
      commit(types.SET_GAME_DATA, { error: error.response || true })
    } finally {
      commit(types.SET_GAME_DATA, { loading: false })
    }
  },
  getUserStats: async ({ commit }) => {
    commit(types.SET_USER_STATS, { loading: true, error: null })

    try {
      const response = await accountApi.getUserStats()
      commit(types.SET_USER_STATS, { data: response.data })
    } catch (error) {
      commit(types.SET_USER_STATS, { error: error.response || true })
    } finally {
      commit(types.SET_USER_STATS, { loading: false })
    }
  },
  logout: ({ commit }) => {
    deleteCookie('token')
    commit(types.CLEAR_ALL)
  },
  getReallocationDateLimit: async ({ commit }) => {
    commit(types.REALLOCATION_DATE_LIMIT, { loading: true })
    try {
      const response = await accountApi.getReallocationDateLimit()

      commit(types.REALLOCATION_DATE_LIMIT, {
        loading: false,
        data: response.data,
      })
    } catch (error) {
      commit(types.REALLOCATION_DATE_LIMIT, {
        loading: false,
        error,
      })
    }
  },
}

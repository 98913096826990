import { success, done } from '@/store/utils'

export default {
  shopItems: (state) => ({
    ...state.shopItems,
    success: success(state.shopItems),
    done: done(state.shopItems),
  }),
  bagItems: (state) => ({
    ...state.bagItems,
    success: success(state.bagItems),
    done: done(state.bagItems),
  }),
  usedItems: (state) => ({
    ...state.usedItems,
    success: success(state.usedItems),
    done: done(state.usedItems),
  }),
  itemEffects: (state) => ({
    ...state.itemEffects,
    success: success(state.itemEffects),
    done: done(state.itemEffects),
  }),
}

import * as types from './types'

export default {
  [types.SET_MAGNA_MISSION](state, magnaMission) {
    state.magnaMission = {
      ...state.magnaMission,
      ...magnaMission,
    }
  },
  [types.SET_CURRENT_ENIGMA_POSITION](state, position) {
    state.currentEnigmaPosition = position
  },
  [types.SET_SELECTED_SAS_QUESTIONS](state, questions) {
    state.selectedSasQuestions = questions
  },
}

import * as types from './types'

export default {
  [types.SET_ACADEMIC_RECORD](state, payload) {
    state.academicRecords = {
      ...state.academicRecords,
      ...payload,
    }
  },
}

import axios from 'axios'

import { addAuthorizationHeader, addXCrossTokenHeader, handleResponseInterceptorError } from '@/utils/auth'
import urls from '@/utils/urls'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

export const apiBff = axios.create({
  baseURL: urls.EUREKA_BFF_API,
  timeout: process.env.VUE_APP_REQUEST_TIMEOUT,
})
const isMicrofrontend = getIsMicrofrontend()

apiBff.interceptors.request.use(addAuthorizationHeader)

if (isMicrofrontend && !(process.env.VUE_APP_MODE === 'production')) {
  apiBff.interceptors.request.use(addXCrossTokenHeader)
}

apiBff.interceptors.response.use((response) => response, handleResponseInterceptorError)

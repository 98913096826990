import { success, done } from '@/store/utils'
import { isNotEmpty } from '@sas-te/frontend-utils/modules/arrays'
import { isEmpty } from 'lodash'

export default {
  currentGrade: (state) => ({
    ...state.currentGrade,
    success: success(state.currentGrade),
    done: done(state.currentGrade),
  }),
  grade: (state, getters) => {
    const currentGrade = getters.currentGrade.data
    const grades = getters.userGrades.data

    if (currentGrade) {
      const foundGrade = grades.find((grade) => grade.id === currentGrade.id)

      if (!isEmpty(foundGrade)) {
        return foundGrade
      }
    }

    return isNotEmpty(grades) ? grades[0] : null
  },
  classrooms: (state) => ({ ...state.classrooms }),
}

export default {
  soundPreferences: (state) => ({
    ...state.soundPreferences,
  }),
  userPreferences: (state) => ({
    ...state.allPreferences,
  }),
  scenesAlreadyPlayedEffects: (state) => ({
    ...state.scenesAlreadyPlayedEffects,
  }),
  effectsAlreadyPlayed: (state, rootState) => {
    const scenesAlreadyPlayedEffects = state.scenesAlreadyPlayedEffects.data
    const sceneId = rootState.currentEpisodeScene.data?.number

    if (sceneId) {
      return scenesAlreadyPlayedEffects.includes(sceneId)
    }

    return null
  },
}

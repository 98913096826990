import { apiBff } from '@/service/apiBff'
import getGradeId from '@/utils/getGradeId'
import * as types from './types'

export default {
  getHistorySeasons: async ({ commit, getters }) => {
    commit(types.SET_SEASONS_LIST, { loading: true, data: null, error: null })

    const params = {}
    const gradeId = getGradeId(getters)

    if (gradeId) {
      params.gradeId = gradeId
    }

    try {
      const response = await apiBff.get('/seasons', { params })
      const seasons = response.data

      commit(types.SET_SEASONS_LIST, {
        data: seasons,
        loading: false,
      })
    } catch (error) {
      commit(types.SET_SEASONS_LIST, {
        data: [],
        error: error.response || true,
        loading: false,
      })
    }
  },
  getHistoryEpisode: async ({ commit }, { episodeId, seasonId }) => {
    commit(types.SET_HISTORY_EPISODE, {
      loading: true,
      data: [],
      error: null,
    })

    try {
      const response = await apiBff.get(`/seasons/${seasonId}/episodes/${episodeId}/scenes`)

      commit(types.SET_HISTORY_EPISODE, { data: response.data })
    } catch ({ response }) {
      commit(types.SET_HISTORY_EPISODE, {
        data: [],
        error: response.data || true,
      })
    } finally {
      commit(types.SET_HISTORY_EPISODE, { loading: false })
    }
  },
  setEpisodeScene({ commit }, scene) {
    if (scene?.episodeId) {
      commit(types.SET_EPISODE_SCENE, {
        data: scene,
      })
    }
  },
  clearHistoryEpisodeAndSceneData({ commit }) {
    commit(types.CLEAR_HISTORY_EPISODE)
    commit(types.SET_EPISODE_SCENE, {
      data: {},
    })
  },
}

import axios from 'axios'

import {
  addAuthorizationHeader,
  addXCrossTokenHeader,
  handleResponseInterceptorError
} from '@/utils/auth'
import urls from '@/utils/urls'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

const isMicrofrontend = getIsMicrofrontend()

const api = axios.create({
  baseURL: urls.ASSIGNMENTS_API,
  timeout: process.env.VUE_APP_REQUEST_TIMEOUT,
})

api.interceptors.request.use(addAuthorizationHeader)

if (isMicrofrontend && !(process.env.VUE_APP_MODE === 'production')) {
  api.interceptors.request.use(addXCrossTokenHeader)
}

api.interceptors.response.use((response) => response, handleResponseInterceptorError)

export default api

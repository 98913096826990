import * as types from './types'

export default {
  [types.SET_SHOP_ITEMS](state, shopItems) {
    state.shopItems = {
      ...state.shopItems,
      ...shopItems,
    }
  },
  [types.SET_BAG_ITEMS](state, bagItems) {
    state.bagItems = {
      ...state.bagItems,
      ...bagItems,
    }
  },
  [types.SET_USED_ITEMS](state, usedItems) {
    state.usedItems = {
      ...state.usedItems,
      ...usedItems,
    }
  },
  [types.SET_ITEM_EFFECTS](state, itemEffects) {
    state.itemEffects = {
      ...state.itemEffects,
      ...itemEffects,
    }
  },
  [types.CLEAR_ITEM_EFFECTS](state) {
    state.itemEffects = {
      loading: false,
      data: [],
      error: null,
    }
  },
  [types.CLEAR_ITEM_EFFECTS_ERROR](state) {
    state.itemEffects = {
      ...state.itemEffects,
      error: null,
    }
  },
  [types.ADD_USED_ITEM](state, usedItem) {
    if (
      Array.isArray(state.usedItems.data)
      && !state.usedItems.data.includes(usedItem)
    ) {
      state.usedItems.data.push(usedItem)
    } else {
      state.usedItems.data = [ usedItem ]
    }
  },
  [types.REMOVE_BAG_ITEM](state, itemToRemove) {
    if (
      Array.isArray(state.bagItems.data)
      && !state.usedItems.data.includes(itemToRemove)
    ) {
      const index = state.bagItems.data.findIndex((bagItem) => (
        bagItem.item.id === itemToRemove
      ))
      if (state.bagItems.data[index].quantity > 1) {
        state.bagItems.data[index].quantity -= 1
      } else {
        state.bagItems.data.splice(index, 1)
      }
    }
  },
  [types.HANDLE_MISSION_ITEM](state, missionItem) {
    if (!state.usedItems.data.length) {
      state.usedItems.data.push({
        category: 'MISSION',
        items: [ missionItem ],
      })
    } else {
      state.usedItems.data.forEach((type) => {
        if (type.category === 'MISSION') {
          if (!type.items.some((item) => item.id === missionItem.id)) {
            type.items.push(missionItem)
          } else {
            const index = type.items.findIndex(
              ({ id }) => id === missionItem.id
            )
            type.items.splice(index, 1)
          }
        }
      })
    }
  },
}

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([ 'user' ]),
    userIsStudent() {
      return this.user.data?.userIsStudent
    },
  },
}

/* eslint-disable max-len */
const ptBR = {
  app: {
    title: 'Tarefa Online',
    gameTitle: 'Eureka',
  },
  splashScreen: {
    waitForTata: 'Tatá ainda está arrumando a mochila para as aventuras de 2021',
    dateOfNews: 'Te esperamos no dia 18/01 com muitas novidades',
    waitPlayingMiniGame: 'Enquanto isso... Divirta-se com o minigame da Tatá!&#128525',
    wasItEasyToGetHere: 'Foi fácil chegar até aqui?',
  },
  commons: {
    start: 'Iniciar',
    play: 'Jogar',
    finish: 'Finalizar',
    resume: 'Continuar',
    finished: 'Finalizado',
    loading: 'Carregando...',
    inProgress: 'Em andamento',
    seeReport: 'Ver relatório',
    back: 'Voltar',
    close: 'Fechar',
    delete: 'Excluir',
    cancel: 'Cancelar',
    tryAgain: 'Tentar novamente',
    explanation: 'Explicação',
    export: 'Exportar',
    report: 'Relatório',
    previous: 'Anterior',
    next: {
      female: 'Próxima',
      male: 'Próximo',
    },
    chapter: 'Capítulo {number}',
    moreDetails: 'Mais detalhes',
    lessDetails: 'Menos detalhes',
    details: 'Detalhes',
    yes: 'Sim',
    no: 'Não',
    ok: 'OK',
    skipIntro: 'Pular introdução',
    skip: 'Pular',
    new: 'Novo',
    error: 'Erro',
    studentsCount: '{count} aluno | {count} alunos',
    extraActivities: 'Atividades extras',
    options: 'Opções',
    sounds: 'Sons',
    music: 'Música',
    newEpisodeUnlocked: 'Novo episódio desbloqueado!',
    questions: '{quantity} questões',
    toView: 'Visualizar',
    leave: 'Sair',
    shutDown: 'Encerrar',
    result: 'Resultado',
  },
  classPicker: {
    selectClassroom: 'Selecionar turma',
    searchClassroom: 'Pesquisar turma',
    emptySearch: 'Nenhuma turma encontrada com "{searchString}"',
  },
  survey: {
    magnaMissionReport: {
      whatDidYouThinkAboutMission: 'O que achou desta missão?',
    },
    report: {
      whatDidYouThinkAboutMission: 'O que achou da missão?',
    },
  },
  ancestralArena: {
    createMagnaMissionModal: {
      title: 'Iniciar Missão Magna',
      subtitle: 'Jogue esta missão com seus alunos durante a aula!',
      selectClassroom: 'Selecione uma turma',
    },
    playMagnaMissionModal: {
      title: 'Jogar Missão Magna',
    },
    optionsModal: {
      teacher: {
        closeMission: 'Fechar Missão',
        cancelMission: 'Encerrar missão',
      },
    },
    view: {
      title: 'Arena Ancestral',
      subtitle: ' Aqui você encontra todas as Missões Magnas criadas!',
      active: 'Ativas',
      closed: 'Encerradas',
      emptyState: {
        resultTitle: 'Você ainda não jogou essa missão com suas turmas',
        resultSubtitle: 'Sempre que você jogar essa missão aqui será exibido histórico de cada partida.',
      },
    },
    lobby: {
      start: 'Iniciar missão',
      description: 'Acesse esta missão pelo Eureka! pelo computador ou aplicativo',
      waitForTeacherStartMission: 'Aguarde o professor iniciar a missão...',
      playersEntered: '{loggedMessage} Jogadores entraram',
      startingMission: 'Iniciando Missão',
    },
    magnaMissionRoom: {
      showQuestion: 'Mostrar questão',
      hideQuestion: 'Ocultar questão',
      error: {
        network: {
          message: 'Parece que você está sem internet... Para continuar você precisa estar conectado.',
        },
        unknown: {
          title: 'Ops, parece que algo deu errado!',
          message: 'Tente atualizar, se o problema continuar aguarde alguns minutos e tente novamente.',
          retryButton: 'Atualizar',
        },
      },
      feedback: {
        waitAnswerRevealed: 'Aguarde a resposta ser revelada...',
        correct: {
          title: 'Uhuuuu! Acertou!!',
          description: 'Aguarde a próxima questão',
        },
        wrong: {
          title: 'Não foi dessa vez...',
          description: 'Aguarde a próxima questão',
        },
      },
    },
    magnaMissionReport: {
      missionResume: 'Resumo da Missão',
      endYourMission: 'Finalizando sua missão...',
      closeReport: 'Fechar Relatório',
      closeMission: 'Fechar Missão',
      studentReport: {
        yourHits: 'Seus acertos',
        hitsCount: 'Você acertou {count} de {total} questões',
      },
      teacherReport: {
        hitsCount: 'Total de {total} questões na missão',
      },
    },
  },
  lectures: {
    science: 'Ciências',
    math: 'Matemática',
    portuguese: 'Língua Portuguesa',
    physics: 'Física',
    chemistry: 'Química',
    biology: 'Biologia',
    geography: 'Geografia',
    history: 'História',
  },
  shifts: {
    morning: 'Manhã',
    afternoon: 'Tarde',
    evening: 'Noite',
    fulltime: 'Integral',
  },
  levels: {
    easy: 'Fácil',
    medium: 'Médio',
    hard: 'Difícil',
  },
  assignments: {
    releasedOn: 'Disponível em {date}',
    empty: {
      title: 'As tarefas estão a caminho!',
      description: 'Em breve serão exibidas aqui.',
      error: {
        title: 'Ocorreu um erro ao mostrar as tarefas da disciplina',
        description: 'Por favor, tente novamente',
      },
    },
  },
  questions: {
    online: 'Você está online novamente. Agora você poderá responder a sua tarefa.',
    offline: 'Você está offline. Para continuar respondendo a tarefa, é preciso estar conectado à internet.',
    progress: 'Questão {current} de {total}',
    questionNumber: 'Questão {current}',
    empty: {
      error: {
        title: 'Ops, não conseguimos mostrar essa questão...',
        description: 'Ocorreu um problema ao tentar carregar a questão, por favor tente novamente.',
      },
      404: {
        title: 'Ops, essa atividade não existe mais',
        description: 'Desculpe, a atividade que você está tentando acessar pode ter sido excluída.',
      },
    },
    actions: {
      answer: 'Responder',
      correct: 'Resposta correta',
      incorrect: 'Resposta incorreta',
      finish: 'Finalizar',
      review: 'Revisar capítulo',
      tips: 'Ver dica',
      tip: 'Dica',
      congratulation: 'Parabéns',
      ops: 'Não foi dessa vez... mas não desista',
    },
    drawer: {
      title: 'Vídeo aula',
      helper: 'Ainda precisa de ajuda?',
      getBook: 'Consultar livro',
      empty: {
        title: {
          review: 'Ops... não conseguimos mostrar esse conteúdo',
        },
        description: {
          review: 'Ocorreu um erro enquanto carregamos o vídeo do capítulo. Por favor, Tente novamente.',
        },
      },
      pageflip: {
        toast: {
          error: {
            message: 'Não conseguimos carregar seu livro. Por favor, tente novamente mais tarde.',
          },
        },
      },
    },
  },
  studentReport: {
    info: 'Seu desempenho em',
    back: 'Voltar para capítulos',
    redo: 'Refazer tarefa',
    assignmentHits: 'Acertos na tarefa',
    hitsCount: '{count} de {total} questão | {count} de {total} questões',
    rightAnswers: 'acerto | acertos',
    wrongAnswers: 'erro | erros',
    confirm: {
      text: 'Tem certeza de que deseja refazer a tarefa?',
      ok: 'Refazer Missão',
      cancel: 'Cancelar',
      activeItem: 'Ativar item na missão',
    },
    empty: {
      title: 'Ops, não conseguimos preparar seu relatório... ',
      description: 'Ocorreu um problema ao preparar seu relatório. Por favor, tente novamente.',
    },
  },
  highSchool: {
    home: {
      title: 'Atividades',
      subtitle: 'Melhore o seu desempenho realizando atividades! ⚡✍',
      practiceBySubject: 'Pratique por disciplina',
      empty: {
        title: 'As tarefas estão a caminho!',
        description: 'Em breve serão exibidas aqui.',
        error: {
          title: 'Ocorreu um erro ao mostrar as disciplinas',
          description: 'Por favor, tente novamente',
        },
      },
    },
    subject: {
      bookAssignmentsSection: {
        title: 'Tarefa On-line',
        subtitle: 'Pratique as atividades do livro!',
      },
      teacherAssignmentsSection: {
        title: 'Atividades do professor',
        empty: {
          title: 'Nenhuma atividade aqui ainda 😥',
          description: 'Parece que seu professor ainda não criou atividades de {lecture} para a sua turma',
        },
      },
    },
    report: {
      info: 'Seu desempenho em',
      back: 'Voltar para capítulos',
      redo: 'Refazer tarefa',
      assignmentHits: 'Acertos na tarefa',
      hitsCount: '{count} de {total} questão | {count} de {total} questões',
      rightAnswers: 'acerto | acertos',
      wrongAnswers: 'erro | erros',
      confirm: {
        text: 'Tem certeza de que deseja refazer a tarefa?',
        ok: 'Refazer',
        cancel: 'Cancelar',
      },
      empty: {
        title: 'Ops, não conseguimos preparar seu relatório... ',
        description: 'Ocorreu um problema ao preparar seu relatório. Por favor, tente novamente.',
      },
    },
    empty: {
      title: 'As tarefas estão a caminho!',
      description: 'Em breve serão exibidas aqui.',
      error: {
        title: 'Ocorreu um erro ao mostrar as tarefas da disciplina',
        description: 'Por favor, tente novamente',
      },
    },
    onboarding: {
      1: {
        title: 'Tarefa On-line agora estará no menu Atividades',
        description: 'Novo visual e várias melhorias para você aproveitar o máximo dos seus estudos!',
      },
      2: {
        title: 'Praticando o Enem',
        description: 'Sinta-se mais preparado para o vestibular praticando com questões das últimas edições do Enem',
      },
      3: {
        title: 'Disponível no App SAS Educação ✌',
        description: 'Agora você também pode praticar direto em seu celular ou tablet pelo nosso aplicativo',
      },
    },
  },
  teacher: {
    viewingEurekaAsStudent: 'Você está visualizando o Eureka como aluno <span>{gradeName}</span>',
  },
  pages: {
    error: {
      401: {
        title: 'Estamos com dificuldade em identificar o seu acesso.',
        type: 'Erro 401 - Não autorizado.',
        comment: 'Pode ter acontecido algo de errado durante o seu login. Vamos levá-lo de volta ao Portal SAS Plataforma de Educação para se autenticar novamente.',
        backButton: 'Voltar ao Portal SAS Plataforma de Educação',
      },
      403: {
        title: 'Você não tem permissão para visitar esta página.',
        type: 'Erro 403 - Proibido.',
        comment: 'Acreditamos que você tenha chegado aqui por engano. Podemos levá-lo de volta para que você continue navegando.',
        backButton: 'Voltar ao Portal SAS Plataforma de Educação',
      },
      404: {
        title: 'Ops! Por essa não esperávamos...',
        type: 'Erro 404 - Página não encontrada',
        comment: 'Acreditamos que você tenha chegado aqui por engano. Podemos levá-lo de volta para que você continue navegando.',
        backButton: 'Voltar para o início',
      },
      500: {
        title: 'Ops! Por essa não esperávamos...',
        type: 'Erro 500 - Erro interno do servidor.',
        comment: 'Encontramos uma instabilidade no sistema, mas vai ficar tudo bem. Volte mais tarde, que já estamos trabalhando nisso.',
        backButton: 'Voltar para o início',
      },
      502: {
        title: 'Nosso serviço está temporariamente indisponível.',
        type: 'Erro 502 - Bad gateway.',
        comment: 'Já estamos trabalhando para resolver o problema e não devemos demorar. Tente novamente mais tarde.',
        backButton: 'Voltar para o início',
      },
      503: {
        title: 'Nosso serviço está temporariamente indisponível.',
        type: 'Erro 503 - Serviço indisponível.',
        comment: 'Já estamos trabalhando para resolver o problema e não devemos demorar. Tente novamente mais tarde.',
        backButton: 'Voltar para o início',
      },
    },
  },
  game: {
    level: 'Nível',
    levelNumber: 'Nível {number}',
    missionNumber: 'Missão {number}',
    useItem: 'Usar item',
    bag: {
      label: 'Mochila',
      title: 'Minha Mochila',
      review: 'Revise a missão',
      myItems: 'Meus itens',
      youOwn: 'Itens que possui',
      empty: {
        message: 'Sua mochila está vazia',
        observation: 'Seus itens comprados aparecerão aqui',
      },
      error: {
        message: 'Parece que algo deu errado com sua mochila',
        observation: 'Tente atualizar, se o erro continuar, volte mais tarde',
        action: 'Atualizar',
      },
    },
    shop: {
      label: 'Mercado',
      signboard: 'Mercado de Relíquias',
      notEnoughCoins: 'Você não possui moedas suficientes',
      buyItem: {
        start: 'Comprar {item} por ',
        end: '?',
      },
      confirm: {
        ok: 'Comprar',
        cancel: 'Cancelar',
      },
      success: 'Você comprou: {itemName}!',
      error: 'Ocorreu um erro ao comprar o item.',
      limitPerWeek: 'Estoque semanal que voce pode comprar por semana',
    },
    feedback: {
      title: 'Avalie o Eureka!',
    },
    items: {
      wiseScroll: {
        name: 'Pergaminho da Sabedoria',
        description: 'Receba uma dica para ajudar a resolver o enigma. Limite de 1 vez por enigma.',
        quick: 'Ver pergaminho novamente',
      },
      missionBook: {
        name: 'Livro da Missão',
        description: 'Revise o conteúdo da missão através do livro digital.',
        quick: 'Ver livro novamente',
      },
      goldenMagnifier: {
        name: 'Lupa Dourada',
        description: '',
        quick: 'Triplique a quantidade de moedas conquistadas em uma missão. ',
      },
      truthCompass: {
        name: 'Bússola da Verdade',
        description: 'Elimine uma alternativa errada do enigma.',
        quick: '',
      },
    },
    eurekaOnBoarding: {
      action: {
        start: 'Começar',
        advance: 'Avançar',
        finish: 'Vamos explorar!',
      },
      hello: 'Olá, {student}!',
      myNameIs: 'Me chamo',
      tata: 'Tatá!',
      letsGo: 'Vamos conhecer o Eureka?',
      worlds: 'Explore seus conhecimentos em missões por diversos mundos!',
      rewards: 'Conquiste moedas e pedras preciosas!',
      items: 'Use itens para ajudar a desvendar os enigmas!',
    },
    missionOnBoarding: {
      message: 'Olá {name}, prepare-se para desvendar os enigmas que aparecerão nesta missão!',
      start: 'Começar missão',
      resume: 'Continuar missão',
      activateItem: 'Ativar item na missão | Item ativado na missão',
    },
    suggestNewWorld: {
      title: 'Explore outros mundos',
      subtitle: 'Você já finalizou todos as missões deste mundo. Que tal explorar o mundo de <strong>{lecture}</strong>?',
      actionLabel: 'Ir para outro mundo',
    },
    quitMission: {
      title: 'Você quer sair?',
      message: 'Seu progresso será salvo para você continuar a missão de onde parou! 😊',
      ok: 'Sair',
      cancel: 'Voltar para a missão',
    },
    reward: {
      levelUpTitle: 'Subiu de nível',
      rankLabel: 'Agora você é',
      ok: 'Continuar',
    },
    report: {
      title: 'Missão Concluída',
      enigmasLabel: 'Você resolveu',
      enigmasCount: {
        of: 'de',
        enigmas: '{count} enigma | {count} enigmas',
      },
      yourScore: 'Sua pontuação',
      rewardsLabel: 'Conquistas',
      usedItems: 'Itens usados',
      back: 'Ir para missões',
      bestScore: 'Sua melhor pontuação',
      bestScoreInfo: '<span>Essa foi a sua melhor pontuação nessa<br> missão. Sua quantidade de gemas é<br> baseada nessa pontuação.</span>',
    },
    redo: {
      label: 'Refazer missão',
      error: 'Ocorreu um erro ao tentar refazer missão.',
    },
    finish: {
      error: 'Ocorreu um erro ao finalizar a missão.',
    },
    adventure: {
      title: 'História',
      subtitle: 'Embarque nas aventuras do Universo Eureka!',
      reward: 'Recompensa:',
      new: 'Novo',
    },
    useEurekaOnApp: 'Use o Eureka! pelo aplicativo',
    openOnApp: 'Abrir no App',
    useEurekaWeb: 'Use o Eureka pelo tablet ou computador',
    error: {
      default: {
        message: 'Parece que algo deu errado...',
        observation: 'Tente atualizar a página e se o erro continuar volte mais tarde',
        action: 'Atualizar',
      },
      403: {
        title: 'Página indisponível',
        message: 'Você está explorando um universo que Eureka não está presente',
        action: 'Voltar para a tela inicial do Portal SAS Plataforma de Educação',
      },
      404: {
        title: 'Página não encontrada',
        message: 'Você explorou tanto que chegou em um lugar que não existe',
        action: 'Ir para mundos',
      },
      500: {
        title: 'Erro interno',
        message: 'Um meteoro caiu e nossos servidores não deram conta',
        observation: 'Tente atualizar a página e se o erro continuar volte mais tarde',
        action: 'Atualizar',
      },
      missions: {
        message: 'Parece que algo deu errado...',
      },
      mission: {
        404: {
          message: 'Parece que essa missão não está completa',
          observation: 'Em breve, você terá novos enigmas para desvendar! ',
          action: 'Ir para missões',
        },
        default: {
          message: 'Parece que algo deu errado...',
          observation: 'Tente atualizar a página e se o erro continuar volte mais tarde',
          action: 'Atualizar',
        },
      },
      report: {
        message: 'Parece que algo deu errado ao gerar seu relatório...',
        observation: 'Tente atualizar a página e se o erro continuar volte mais tarde',
        action: 'Atualizar',
      },
      answer: {
        message: 'Ops, aconteceu um problema... Por favor, tente enviar sua resposta novamente.',
      },
      redo: {
        message: 'Ops, aconteceu um problema... Por favor, tente refazer a missão novamente.',
      },
      shop: {
        message: 'Parece que algo deu errado ao abrir a loja...',
        observation: 'Tente atualizar a página e se o erro continuar volte mais tarde',
        action: 'Atualizar',
      },
      buyItem: {
        message: 'Ops, aconteceu um problema... Por favor, tente comprar novamente',
      },
      useItem: {
        message: 'Ops, aconteceu um problema... Por favor, tente usar o item novamente',
      },
    },
    news: {
      news: 'Novidades',
      readMore: 'Leia mais',
      error: {
        title: 'Ops, parece que algo deu errado...',
        description: 'Tente atualizar a página, se o erro continuar, volte mais tarde',
        action: 'Atualizar',
      },
    },
  },
  adaptiveSelector: {
    emptyString: 'Nenhum resultado.',
  },
}

export default ptBR
/* eslint-enable max-len */

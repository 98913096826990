import * as types from './types'

import initialState from './initialState'

export default {
  [types.SET_TOAST](state, payload) {
    state.toast = {
      ...state.toast,
      ...payload,
    }
  },
  [types.SET_TOAST_INITIAL](state) {
    state.toast = {
      ...initialState,
    }
  },
}

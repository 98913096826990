import api from '@/service/api'
import * as types from './types'
import { getContentYear } from '../utils'

export default {
  getLectures: ({ commit, rootState }, gradeId) => {
    const { grades } = rootState.account.user.data
    const { contractType } = rootState.account.user.data.grades[0]
    const currentContentYear = rootState.account.user.data.contentYear

    const contentYear = getContentYear(
      currentContentYear,
      contractType,
      grades,
      gradeId
    )

    const params = {
      contentYear,
    }

    if (gradeId) {
      params.gradeId = gradeId
    }

    commit(types.SET_LECTURES, {
      loading: true,
      error: null,
    })

    return api.get('/pendent-lectures', { params })
      .then((response) => {
        commit(types.SET_LECTURES, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_LECTURES, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getLecture: async ({ commit }, lectureId) => {
    commit(types.SET_LECTURE, { loading: false })

    try {
      const response = await api.get(`/lectures/${lectureId}`)
      commit(types.SET_LECTURE, { data: response.data })
    } catch (error) {
      commit(types.SET_LECTURE, { error: error.response || true })
    } finally {
      commit(types.SET_LECTURE, { loading: false })
    }
  },
  setLecture({ commit }, lecture) {
    commit(types.SET_LECTURE, { data: lecture })
  },
  getNextLecture: ({ commit, rootState }, { gradeId, lectureId }) => {
    const { grades } = rootState.account.user.data
    const { contractType } = rootState.account.user.data.grades[0]
    const currentContentYear = rootState.account.user.data.contentYear

    const contentYear = getContentYear(
      currentContentYear,
      contractType,
      grades,
      gradeId
    )

    commit(types.SET_NEXT_LECTURE, { loading: true })

    const params = {
      contentYear,
    }

    return api.get(`/grades/${gradeId}/lectures/${lectureId}/next`, { params })
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.data.status !== 404) {
          throw error
        }

        return null
      })
      .then((nextLectureId) => {
        commit(types.SET_NEXT_LECTURE, {
          data: nextLectureId,
          loading: false,
        })
      })
  },
  resetNextLecture: ({ commit }) => {
    commit(types.SET_NEXT_LECTURE, {
      data: undefined,
      loading: false,
    })
  },
}

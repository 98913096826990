import questionsApi from '@/service/questions'
import * as types from './types'
import * as assignmentTypes from '../assignments/types'

const resetReviewTip = (commit) => {
  commit(types.SET_TIP, {
    loading: false,
    data: {},
    error: null,
  })
}
const resetReviewVideo = (commit) => {
  commit(types.SET_VIDEO, {
    loading: false,
    data: {},
    error: null,
  })
}
const resetPageFlip = (commit) => {
  commit(types.SET_PAGEFLIP, {
    loading: false,
    data: {},
    error: null,
  })
}

export default {
  getQuestion: async ({ commit }, params = {}) => {
    commit(types.SET_QUESTION, { loading: true, data: {}, error: null })
    commit(types.SET_ANSWER, { loading: false, data: {}, error: null })

    return questionsApi.fetchQuestion(params)
      .then((questionResponse) => {
        commit(types.SET_QUESTION, {
          data: questionResponse.data,
          loading: false,
        })
        commit(assignmentTypes.SET_ASSIGNMENT, {
          data: {
            title: questionResponse.data.assignment.title,
            subtitle: questionResponse.data.assignment.subtitle,
          },
        })
        commit(types.SET_ANSWER, {
          data: questionResponse.data.answer,
        })
        commit(types.SET_PERFORMANCE, {
          data: questionResponse.data.performance,
        })

        commit(types.SET_PROGRESS, { loading: true, data: {}, error: null })
        questionsApi.fetchProgress(questionResponse.data.assignment.code)
          .then((progressResponse) => {
            commit(types.SET_PROGRESS, {
              data: progressResponse.data,
              loading: false,
            })
          })
          .catch((error) => {
            commit(types.SET_PROGRESS, {
              error: error.response || true,
              loading: false,
            })
          })
      })
      .catch((error) => {
        commit(types.SET_QUESTION, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  clearQuestion: ({ commit }) => {
    commit(types.CLEAR_QUESTION)
  },
  sendAnswer: ({ commit }, {
    assignmentCode,
    answerBody,
  }) => {
    commit(types.SET_ANSWER, { loading: true, data: {}, error: null })

    return questionsApi.postAnswer({ assignmentCode, answerBody })
      .then((response) => {
        commit(types.SET_ANSWER, {
          data: response.answer,
          loading: false,
          error: null,
        })
        if (response.missionData?.performance) {
          commit(types.SET_PERFORMANCE, {
            data: response.missionData.performance,
          })
        }
        if (response.missionData?.rewards) {
          commit(types.SET_REWARDS, {
            data: response.missionData.rewards,
          })
        }
      })
      .catch((error) => {
        commit(types.SET_ANSWER, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getPageFlip: async ({ commit }, questionnaireCode) => {
    resetPageFlip(commit)
    commit(types.SET_PAGEFLIP, { loading: true })

    return questionsApi.fetchPageFlips(questionnaireCode)
      .then((response) => {
        if (response === undefined || !response.contentPath) {
          commit(types.SET_PAGEFLIP, {
            error: true,
            loading: false,
          })

          return Promise.reject()
        }

        commit(types.SET_PAGEFLIP, {
          data: response,
          loading: false,
        })

        return response
      })
      .catch((error) => {
        commit(types.SET_PAGEFLIP, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getVideo: ({ commit }, questionnaireCode) => {
    resetReviewVideo(commit)
    commit(types.SET_VIDEO, { loading: true })

    return questionsApi.fetchVideo(questionnaireCode)
      .then((response) => {
        commit(types.SET_VIDEO, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_VIDEO, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  resetTip: async ({ commit }) => {
    resetReviewTip(commit)
  },
  resetVideo: async ({ commit }) => {
    resetReviewVideo(commit)
  },
  resetPageFlip: async ({ commit }) => {
    resetPageFlip(commit)
  },
}

import { getAuthToken } from '@/utils/auth'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  user: {
    data: null,
    loading: false,
    error: null,
  },
  gameData: {
    data: null,
    loading: false,
    error: null,
  },
  userStats: {
    data: null,
    loading: false,
    error: null,
  },
  userGrades: {
    data: null,
    loading: false,
    error: null,
  },
  token: getAuthToken(),
  reallocationDateLimit: {
    loading: false,
    data: null,
    error: null,
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

import { success, done } from '@/store/utils'

export default {
  theme: (state) => ({
    ...state.theme,
    success: success(state.theme),
    done: done(state.theme),
  }),
  themes: (state) => ({
    ...state.themes,
    success: success(state.themes),
    done: done(state.themes),
  }),
}

import * as types from './types'

export default {
  [types.SET_CURRENT_GRADE](state, grade) {
    state.currentGrade = {
      ...state.currentGrade,
      ...grade,
    }
  },
  [types.SET_CLASSROOMS](state, classrooms) {
    state.classrooms = {
      ...state.classrooms,
      ...classrooms,
    }
  },
  [types.SET_ACTIVE_CLASSROOM](state, classroom) {
    state.activeClassroom = {
      ...state.activeClassroom,
      ...classroom,
    }
  },
}

import urls from '@/utils/urls'
import api from './api'

const config = { baseURL: urls.AUTH_API }
const configV2 = { baseURL: urls.AUTH_API_V2 }
const configEureka = { baseURL: urls.EUREKA_BFF_API }

export default {
  getUser: () => api.get('/users/me', config),
  getPersonalData: () => api.get('personal-data', configV2),
  getUserGrades: () => api.get('game/grades'),
  getSignedIn: () => api.get('/signin'),
  getUserGameData: (params) => api.get('/v2/students', { params }),
  getUserStats: () => api.get('/students/me/stats'),
  getReallocationDateLimit: () => api.get('/users/me/reallocation-login-date-limit', configV2),
  postExchangeToken: (token) => api.post('/v1/exchange-token/onb-to-sas/', { token }, configEureka),
}

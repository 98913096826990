import * as types from './types'

export default {
  [types.SET_ASSIGNMENTS](state, assignments) {
    state.assignments = {
      ...state.assignments,
      ...assignments,
    }
  },
  [types.SET_ASSIGNMENT](state, assignment) {
    state.assignment = {
      ...state.assignment,
      ...assignment,
    }
  },
  [types.SET_REPORT](state, report) {
    state.report = {
      ...state.report,
      ...report,
    }
  },
  [types.SET_REDO](state, redo) {
    state.redo = {
      ...state.redo,
      ...redo,
    }
  },
}

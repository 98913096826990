import Vue from 'vue'
import Router from 'vue-router'
import isStudent from '@/utils/isStudent'
import { isNotEmpty } from '@sas-te/frontend-utils/modules/arrays'
import { isAuthenticated, logout } from '@/utils/auth'
import AncestralArenaList from '@/views/AncestralArena/AncestralArenaList'
import isTeacherOrManager from './utils/isTeacherOrManager'

const GameBaseLayout = () => import('@/layouts/GameBaseLayout')
const Worlds = () => import('@/views/Worlds/Worlds')
const Missions = () => import('@/views/Missions/Missions')
const Mission = () => import('@/views/Mission/Mission')
const MissionReportView = () => import('@/views/MissionReport/MissionReportView')
const Profile = () => import('@/views/Profile/Profile')
const Shop = () => import('@/views/Shop/Shop')
const GameError = () => import('@/views/GameError')
const Story = () => import('@/components/Adventure/Story')
const HomeTeacher = () => import('@/views/HomeTeacher')
const AncestralArenaTeacherLayout = () => import('@/views/AncestralArena/layouts/AncestralArenaTeacherLayout')
const AncestralArenaStudentLayout = () => import('@/views/AncestralArena/layouts/AncestralArenaStudentLayout')
const MagnaMissionLobbyTeacher = () => import('@/views/AncestralArena/MagnaMissionLobby/MagnaMissionLobbyTeacher')
const MagnaMissionLobbyStudent = () => import('@/views/AncestralArena/MagnaMissionLobby/MagnaMissionLobbyStudent')
const MagnaMissionStudentRoom = () => import('@/views/AncestralArena/MagnaMissionRoom/MagnaMissionStudentRoom')
const MagnaMissionStudentHomeRoom = () => import('@/views/AncestralArena/StudentHomeMission/components/MagnaMissionStudentHomeRoom/MagnaMissionStudentHomeRoom')
const MissionSummaryPage = () => import('@/views/AncestralArena/StudentHomeMission/components/MagnaMissionStudentHomeRoom/components/MissionSummaryPage/MissionSummaryPage')
const TeacherFinishedMission = () => import('@/views/AncestralArena/StudentHomeMission/components/TeacherFinishedMission/TeacherFinishedMission')
const MagnaMissionTeacherRoom = () => import('@/views/AncestralArena/MagnaMissionRoom/MagnaMissionTeacherRoom')
const MagnaMissionStudentReport = () => import('@/views/AncestralArena/MagnaMissionReport/MagnaMissionStudentReport')
const MagnaMissionTeacherReport = () => import('@/views/AncestralArena/MagnaMissionReport/MagnaMissionTeacherReport')
const MagnaMissionDetail = () => import('@/views/AncestralArena/MagnaMissionDetail/MagnaMissionDetail')
const RetrospectiveView = () => import('@/views/Retrospective/RetrospectiveView')
const CreateMagnaMissionLayout = () => import('@/views/AncestralArena/CreateMagnaMissionLayout/CreateMagnaMissionLayout')
const CreateMagnaMission = () => import('@/views/AncestralArena/CreateMagnaMissionLayout/CreateMagnaMission/CreateMagnaMission')
const MagnaMissionQuestionSearch = () => import('./views/AncestralArena/CreateMagnaMissionLayout/MagnaMissionQuestionSearch/MagnaMissionQuestionSearch')
const MagnaMissionLobbyStudentHomeMission = () => import('@/views/AncestralArena/StudentHomeMission/components/MagnaMissionLobbyStudentHomeMission/MagnaMissionLobbyStudentHomeMission')
const AncestralArenaStudentHomeLayout = () => import('@/views/AncestralArena/StudentHomeMission/AncestralArenaStudentHomeLayout')
const LogosSubjectSelector = () => import('@/views/Logos/SubjectSelector/SubjectSelector')
const LogosKnowledgeTest = () => import('@/views/Logos/KnowledgeTest/KnowledgeTest')
const LogosHeadingTopics = () => import('@/views/Logos/HeadingTopics/HeadingTopics')
const LogosWelcomeScreen = () => import('@/views/Logos/WelcomeScreen/WelcomeScreen')
const LogosMissionStart = () => import('@/views/Logos/MissionStart/MissionStart')
const LogosRecovery = () => import('@/views/Logos/Recovery/Recovery')
const LogosMissionReportView = () => import('@/views/Logos/MissionReport/MissionReportView')

if (process.env.NODE_ENV !== 'test') {
  Vue.use(Router)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'base-layout',
      component: GameBaseLayout,
      children: [
        {
          path: 'eureka/home',
          alias: 'home',
          name: 'home-teacher',
          component: HomeTeacher,
          async beforeEnter(to, from, next) {
            const userIsStudent = await isStudent()

            if (!userIsStudent) {
              next()
            } else {
              next({ name: 'worlds' })
            }
          },
        },
        {
          path: 'eureka/worlds',
          alias: 'worlds',
          name: 'worlds',
          component: Worlds,
          props(route) {
            return {
              hasInitialSplashScreen: 'hasInitialSplashScreen' in route.params
                ? route.params.hasInitialSplashScreen
                : true,
            }
          },
        },
        {
          path: 'eureka/',
          alias: 'worlds',
          name: 'worlds',
          component: Worlds,
          props(route) {
            return {
              hasInitialSplashScreen: 'hasInitialSplashScreen' in route.params
                ? route.params.hasInitialSplashScreen
                : true,
            }
          },
        },
        {
          path: 'eureka/lectures/:lectureId/assignments',
          alias: 'lectures/:lectureId/assignments',
          name: 'assignments',
          component: Missions,
          props: (route) => ({
            ...route.params,
            lectureId: parseInt(route.params.lectureId, 10),
            chapterId: parseInt(route.query.chapterId, 10) || null,
          }),
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/lectures/:lectureId/chapter/:chapterId/questionnaires/:questionnaireCode/question',
          alias: '/lectures/:lectureId/chapter/:chapterId/questionnaires/:questionnaireCode/question',
          name: 'question',
          component: Mission,
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/lectures/:lectureId/chapter/:chapterId/questionnaires/:questionnaireCode/report',
          alias: 'lectures/:lectureId/chapter/:chapterId/questionnaires/:questionnaireCode/report',
          name: 'report',
          component: MissionReportView,
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/profile',
          alias: 'profile',
          name: 'profile',
          component: Profile,
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/shop',
          alias: 'shop',
          name: 'shop',
          component: Shop,
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/seasons/:seasonId/episode/:episodeId/scenes',
          alias: 'seasons/:seasonId/episode/:episodeId/scenes',
          name: 'storytelling',
          component: Story,
          params: { hasInitialSplashScreen: false },
          meta: { hideHeader: true },
        },
        {
          path: 'eureka/retrospective/:retrospectiveId/',
          alias: 'retrospective/:retrospectiveId/',
          name: 'retrospective',
          component: RetrospectiveView,
          meta: {
            hideHeader: true,
          },
          async beforeEnter(to, from, next) {
            const userIsStudent = await isStudent()

            if (userIsStudent) {
              next()
            } else {
              next({ name: 'profile' })
            }
          },
        },
        {
          path: 'eureka/ancestral-arena',
          alias: 'ancestral-arena',
          component: AncestralArenaList,
          name: 'arena',
        },
        {
          path: 'eureka/ancestral-arena/teacher',
          alias: 'ancestral-arena/teacher',
          name: 'ancestral-arena-teacher',
          component: AncestralArenaTeacherLayout,
          async beforeEnter(to, from, next) {
            const userIsTeacherOrManager = await isTeacherOrManager()

            if (userIsTeacherOrManager) {
              next()
            } else {
              next({ name: 'arena' })
            }
          },
          children: [
            {
              path: 'questionnaires/:questionnaireCode/detail',
              name: 'magna-mission-detail',
              component: MagnaMissionDetail,
            },
            {
              path: 'questionnaires/:questionnaireCode/detail-live-mission',
              name: 'magna-mission-detail-live-mission',
              component: MagnaMissionDetail,
              props: (route) => ({
                ...route.params,
                isActiveMission: true,
              }),
            },
            {
              path: 'questionnaires/:questionnaireCode/lobby',
              name: 'lobby-teacher',
              component: MagnaMissionLobbyTeacher,
            },
            {
              path: 'classrooms/:classroomId/questionnaires/:questionnaireCode/room',
              name: 'magna-mission-room-teacher',
              component: MagnaMissionTeacherRoom,
            },
            {
              path: 'classrooms/:classroomId/questionnaires/:questionnaireCode/report',
              name: 'magna-mission-teacher-report',
              component: MagnaMissionTeacherReport,
            },
          ],
        },
        {
          path: 'eureka/ancestral-arena/teacher/mission/:questionnaireCode?',
          alias: 'ancestral-arena/teacher/mission/:questionnaireCode?',
          name: 'magna-mission-factory',
          component: CreateMagnaMissionLayout,
          redirect: { name: 'magna-mission-create-edit' },
          children: [
            {
              path: 'create-edit',
              name: 'magna-mission-create-edit',
              component: CreateMagnaMission,
            },
            {
              path: 'questions-search',
              name: 'magna-mission-questions-search',
              component: MagnaMissionQuestionSearch,
            },
          ],
          props: (route) => ({
            ...route.params,
            hasRequiredData: isNotEmpty(Object.keys(route.params)),
          }),
          async beforeEnter(to, from, next) {
            const userIsTeacherOrManager = await isTeacherOrManager()

            if (userIsTeacherOrManager) {
              next()
            } else {
              next({ name: 'arena' })
            }
          },
        },
        {
          path: 'eureka/ancestral-arena/student/live',
          alias: 'ancestral-arena/student/live',
          name: 'ancestral-arena-student',
          component: AncestralArenaStudentLayout,
          children: [
            {
              path: 'questionnaires/:questionnaireCode/lobby',
              name: 'lobby-student',
              component: MagnaMissionLobbyStudent,
            },
            {
              path: 'questionnaires/:questionnaireCode/room',
              name: 'magna-mission-room-student',
              component: MagnaMissionStudentRoom,
            },
            {
              path: 'questionnaires/:questionnaireCode/report',
              name: 'magna-mission-student-report',
              component: MagnaMissionStudentReport,
            },
          ],
        },
        {
          path: 'eureka/ancestral-arena/student/home',
          alias: 'ancestral-arena/student/home',
          name: 'ancestral-arena-student-home',
          component: AncestralArenaStudentHomeLayout,
          children: [
            {
              path: 'questionnaires/:questionnaireCode/lobby',
              name: 'lobby-student-home-mission',
              component: MagnaMissionLobbyStudentHomeMission,
            },
            {
              path: 'questionnaires/:questionnaireCode/room',
              name: 'magna-mission-student-home-room',
              component: MagnaMissionStudentHomeRoom,
            },
            {
              path: 'questionnaires/:questionnaireCode/:assignmentCode/report',
              name: 'mission-summary-page',
              component: MissionSummaryPage,
            },
          ],
        },
        {
          alias: 'eureka/logos',
          path: 'logos',
          name: 'logos',
          component: LogosWelcomeScreen,
          props(route) {
            return {
              hasInitialSplashScreen: 'hasInitialSplashScreen' in route.params
                ? route.params.hasInitialSplashScreen
                : true,
              gradeId: parseInt(route.query.gradeId, 10) || null,
            }
          },
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/logos-subjects',
          alias: 'logos/subjects',
          name: 'logos-subjects',
          component: LogosSubjectSelector,
        },
        {
          path: 'eureka/logos/knowledge-test/:questionnaireCode/:assignmentCode/:chapterId',
          alias: 'logos/knowledge-test/:questionnaireCode/:assignmentCode/:chapterId',
          name: 'logos-knowledge-test',
          component: LogosKnowledgeTest,
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/logos/recovery/:questionnaireCode/:assignmentCode/:chapterId',
          alias: 'logos/recovery/:quesrstionnaireCode/:assignmentCode/:chapterId',
          name: 'logos-recovery',
          component: LogosRecovery,
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/logos/mission-start/:questionnaireCode/:chapterId/:chapterNumber/:title/',
          alias: 'logos/mission-start/:questionnaireCode/:chapterId/:chapterNumber/:title/',
          name: 'logos-mission-start',
          component: LogosMissionStart,
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/logos/questionnaires/:questionnaireCode/:assignmentCode/report',
          alias: 'logos/questionnaires/:questionnaireCode/:assignmentCode/report',
          name: 'logos-mission-report',
          component: LogosMissionReportView,
          meta: {
            hideHeader: true,
          },
        },
        {
          path: 'eureka/logos/subject/:subjectId',
          alias: 'logos/subject/:subjectId',
          name: 'logos-heading-topics',
          component: LogosHeadingTopics,
          meta: {
            hideHeader: true,
          },
        },
      ],
    },
    {
      path: 'eureka/finished-mission',
      alias: '/finished-mission',
      name: 'teacher-finished-mission',
      component: TeacherFinishedMission,
    },
    {
      path: 'eureka/*',
      alias: '*',
      name: 'gamer-error',
      component: GameError,
      props: {
        status: 404,
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.name !== from.name) {
      return { x: 0, y: 0 }
    }

    return null
  },
})

router.beforeEach((to, from, next) => {
  if (isAuthenticated()) {
    next()
  } else {
    logout({ redirectBack: true })
    next(false)
  }
})

export default router

import * as types from './types'

export default {
  [types.SET_THEME](state, theme) {
    state.theme = {
      ...state.theme,
      ...theme,
    }
  },
  [types.SET_THEMES](state, theme) {
    if (!theme) {
      state.themes.data = []
    } else {
      state.themes.data.push(theme)
    }
  },
}

import Vue from 'vue'

import 'floating-vue/dist/style.css'
import '@/styles/_floating-vue.scss'

import {
  VTooltip,
  VClosePopper,
  Dropdown,
  Tooltip
} from 'floating-vue'

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popper', VClosePopper)

Vue.component('VDropdown', Dropdown)
Vue.component('VTooltip', Tooltip)

<template>
  <div
    :class="['g-toast', `--${variation}`]"
    @mouseenter="stopTimer"
    @mouseleave="startTimer"
  >
    <img
      v-if="asset"
      class="g-toast__icon"
      :src="require(`@/assets/${asset}`)"
      alt=""
    >
    <div class="g-toast__body">
      <strong class="g-toast__text">{{ text }}</strong>
      <p
        v-show="hasDescription"
        class="g-toast__description"
      >
        {{ description }}
      </p>
    </div>

    <button
      aria-label="fechar toast"
      class="g-toast__close-btn"
      @click="close"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es'

let timer = null

export default {
  name: 'GToast',
  props: {
    description: {
      type: String,
      required: false,
      default: '',
    },
    // TODO: alterar prop Text para Title
    text: {
      type: String,
      required: true,
    },
    autoClose: {
      type: Number,
      default: 5000,
    },
    asset: {
      type: String,
      default: '',
    },
    variation: {
      type: String,
      default: 'success',
      validator: (value) => [ 'success', 'error', 'info', 'error-secondary' ].includes(value),
    },
  },
  computed: {
    hasDescription() {
      return !isEmpty(this.description)
    },
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    this.stopTimer()
  },
  methods: {
    close() {
      this.stopTimer()
      this.$emit('close')
    },
    stopTimer() {
      clearInterval(timer)
    },
    startTimer() {
      timer = setInterval(() => {
        this.close()
        clearInterval(timer)
      },
      this.autoClose)
    },
  },
}
</script>

<style lang="scss" scoped>
.g-toast {
  @include flex-center-start;
  position: fixed;
  bottom: 5.21%;
  left: 2.93%;
  background-color: $eureka-color-success;
  padding: 12px $size_s;
  box-shadow: 0 20px 36px -8px rgba($color-black, 0.42);
  border-radius: $border-radius-m;
  animation: animate-top 1s;

  &__icon {
    position: relative;
    top: -7px;
    margin: {
      left: 3px;
      right: 11px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font: {
      weight: $font-weight-regular;
      family: Rowdies;
    }
    line-height: $line-height-heading;
    text-shadow: none;
    color: $color-white;
  }

  &__description {
    font: {
      weight: $font-weight-medium;
      family: Lato;
      size: $font-size-m;
    }
    color: $color-white;
  }

  &__close-btn {
    box-sizing: content-box;
    width: $size-s;
    height: $size-s;
    cursor: pointer;
    border: 0;
    margin-left: 10px;
    background: {
      color: transparent;
      image: url('~@/assets/close.svg');
      position: center;
      repeat: no-repeat;
    }
  }

  &.--info {
    background-color: $eureka-color-notice;
  }

  &.--error {
    background-color: $eureka-color-danger;
  }

  &.--error-secondary {
    background-color: $color-danger-lightest;
    max-height: 75px;
    width: 535px;

    .g-toast {
      &__body {
        width: 100%;
        margin-left: $size-s;
      }

      &__icon {
        top: -5px;
      }

      &__text {
        color: $color-danger-darkest;
      }

      &__description {
        width: 100%;
        color: $color-danger-darkest;
      }

      &__close-btn {
        position: relative;
        top: -20px;
        right: -10px;
        background-image: url('~@/assets/close_big.svg');
        color: $color-danger-darkest;
        fill: $color-danger-darkest;
      }
    }
  }
}

@keyframes animate-top {
  0% { bottom: 0; }
  100% { bottom: 5.21%;}
}
</style>

import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'

import router from '@/router'

function getEnvConfigs() {
  if (process.env.NODE_ENV === 'test') {
    return {
      id: 'GTM-XXXXXX',
      enabled: false,
      debug: false,
    }
  }

  return {
    id: process.env.VUE_APP_TRACKING_GTM_ID,
    enabled: Boolean(process.env.VUE_APP_TRACKING_ENABLED),
    debug: process.env.VUE_APP_MODE !== 'production',
  }
}

const envConfigs = getEnvConfigs()

Vue.use(VueGtm, {
  id: envConfigs.id,
  enabled: envConfigs.enabled,
  debug: envConfigs.debug, // pode ser ativada via unleash
  loadScript: false, // is being loaded in `/public/index.html`
  vueRouter: router,
})

Vue.prototype.$trackEvent = ({
  category,
  action,
  label,
  ...rest
}) => {
  Vue.gtm.push({
    event: 'custom_event_analytics',
    category,
    action,
    label,
    ...rest,
  })
}

Vue.prototype.$track = {
  category: {
    eureka: 'eureka',
    eurekaTeacher: 'EurekaProfessor',
    mission: 'Missao',
    report: 'Relatorio',
    worlds: 'Mundos',
    menus: 'Menus',
    history: 'Historia',
    news: 'Novidades',
    trackMissions: 'TrilhaMissoes',
    modalMission: 'ModalMissao',
    relicsMarket: 'MercadoDeReliquias',
    feedback: 'Feedback',
    ancestralArena: 'ArenaAncestral',
    homeTeacher: 'HomeProfessor',
    profile: 'Perfil',
    soundEffects: 'EfeitosSonoros',
    Historical: 'Historico',
    Retrospective: 'Retrospectiva',
    knowledgeTest: 'TesteDeConhecimento',
    thematicUnit: 'UnidadeTematica',
    trainingMission: 'MissaoDeTreino',
    logos: 'Lógos',
    logosWorld: 'MundosLogos',
    logosMission: 'MissaoLogos',
    rescueOfKnowledge: 'ResgateDeConhecimento',
  },
  action: {
    enter: 'entrou',
    leave: 'saiu',
    buyItem: 'ComprarItem',
    finish: 'FinalizarMissao',
    upLevel: 'SubirNivel',
    enterBag: 'EntrarMochila',
    enterMarket: 'EntrarMercado',
    redoMission: 'RefazerMissao',
    goToMissions: 'IrParaMissoes',
    receiveReward: 'ReceberRecompensa',
    worldsVisualization: 'VisualizacaoMundos',
    watchHistory: 'AssistirHistoria',
    exitHistory: 'SairHistoria',
    openSettings: 'AbrirConfiguracoes',
    skipHistory: 'PularHistoria',
    exitNews: 'SairNovidades',
    readNews: 'LerNoticia',
    choiceGrade: 'EscolherSerie',
    enableItem: 'HabilitarItem',
    startMission: 'IniciarMissao',
    continueMission: 'ContinuarMissao',
    closeModalMission: 'FecharModalMissao',
    answer: 'Responder',
    quitMission: 'SairMissao',
    goback: 'Voltar',
    toGoOtherWorld: 'IrParaOutroMundo',
    closeModalOtherWorld: 'FecharModalOutroMundo',
    feedbackSurvey: 'AvaliarExperiencia',
    replyFeedback: 'ResponderFeedback',
    viewMission: 'VisualizarMissao',
    openArenaMission: 'ProfSelecionarMissaoArena',
    teacherOpenLiveArena: 'ProfAbriuArenaAoVivo',
    teacherOpenHomeArena: 'ProfAbriuArenaParaCasa',
    teacherSelectLiveMission: 'ProfSelecionarMissaoAoVivo',
    teacherSelectHomeMission: 'ProfSelecionarMissaoParaCasa',
    createMagnaMission: 'ProfCriarMissao',
    selectClassroom: 'ProfSelecionarTurma',
    addMagnaMissionQuestions: 'ProfAdicionarQuestao',
    saveEditions: 'ProfSalvarAlteracoes',
    savedChanges: 'ProfAlteracoesSalvas',
    studentSelectMission: 'AlunoSelecionarMissao',
    studentPlayMission: 'AlunoJogarMissao',
    leaveMatch: 'SairPartida',
    leaveMatchConfirms: 'SairPartidaConfirma',
    openContentMission: 'AbrirMissiaoConteudo',
    startMissionClass: 'IniciarMissaoTurma',
    startMissionLobby: 'ProfIniciarMissaoLobby',
    cancelMagnaMission: 'CancelarMissaoMagna',
    abortMagnaMission: 'AbortarMissaoMagna',
    finishMagnaMission: 'ProfFinalizarMissao',
    studentFinishMagnaMission: 'AlunoFinalizarMissao',
    seeFullReport: 'ProfVerRelatorioCompleto',
    changeGameIcon: 'TrocarGameIcon',
    soundPreferences: 'PreferenciaDeSons',
    musicPreferences: 'PreferenciaDeMusica',
    soundEffectsPreferences: 'PreferenciaDeEfeitosSonoros',
    enterReport: 'EntrouRelatorio',
    accessHistorical: 'AcessarHistorico',
    accessRetrospective: 'AcessarRetrospectiva',
    closeRetrospective: 'FecharRetrospectiva',
    finishOnboarding: 'FinalizarOnboarding',
    closeOnboarding: 'FecharOnboarding',
    returnOnboarding: 'VoltarOnboarding',
    advanceOnboarding: 'AvancarOnboarding',
    exitButtonOnboardingArena: 'BotaoSairOnboardingArena',
    closeButtonOnboardingArena: 'BotaoFecharXOnboardingArena',
    advanceOnboardingArena: 'AvancarOnboardingArena',
    returnOnboardingArena: 'VoltarOnboardingArena',
    teacherAddImage: 'ProfAdicionarImagem',
    teacherStartMission: 'ProfIniciarMissao',
    teacherConcludedSASQuestionSelection: 'ProfConcluiSelecaodeQuestoesSAS',
    teacherSelectQuestionType: 'ProfSelecionaTipoDeQuestao',
    teacherCreateMission: 'ProfCriaMissaoMagna',
    teacherEditMission: 'ProfEditaMissaoMagna',
    teacherStartLiveMissionLobby: 'ProfIniciaMissaoAoVivoLobby',
    activeMissionsTab: 'AbaPartidasAtivas',
    finishedMissionsTab: 'AbaPartidasFinalizadas',
    viewedMission: 'VisualizarMissao',
    studentLeftHomeMission: 'AlunoSaiuMissãoParaCasa',
    studentSelectHomeMission: 'AlunoSelecionarMissaoParaCasa',
    studentFinishedHomeMission: 'AlunoFinalizarMissãoParaCasa',
    studentPlayHomeMission: 'AlunoJogarMissãoParaCasa',
    startTest: 'IniciarTeste',
    selectThematicUnit: 'SelecionarUnidadeTematica',
    getExplanation: 'PedirExplicacao',
    startTrainingMission: 'IniciarMissaoDeTreino',
    finishProgressionAxis: 'FinalizarEixoDeProgressao',
    watchVideo: 'AssistirVideo',
    exitTest: 'SairDoTeste',
    finishTest: 'FinalizarTeste',
    finishTrainingMission: 'FinalizarMissaoDeTreino',
    selectDiscipline: 'SelecionarDisciplina',
    startLogosMission: 'IniciarMissaoLogos',
    finishLogosMission: 'FinalizarMissaoLogos',
    requestExplanationLogosMission: 'PedirExplicacaoMissaoLogos',
    leaveLogosMission: 'SairMissaoLogos',
    startRescueLogos: 'IniciarResgate',
    finishRescueLogos: 'FinalizarResgate',
    watchRescueVideo: 'AssistirVideoResgate',
    retryRescueMission: 'RefazerResgate',
    collectInsignia: 'ColetarInsignia',
    answerQuestion: 'ResponderQuestao',
    watchChapterVideo: 'AssistirVideoCapítulo',
    accessStudentPageflip: 'AcessarPageflipEstudante',
  },
  label: {
    mission: 'missão',
    bag: 'bolsa',
    wiseScroll: 'pergaminho do sábio',
    truthCompass: 'Bússola da verdade',
    missionBook: 'livro da missão',
    goldenMagnifier: 'Lupa Dourada',
    shop: 'loja',
    profile: 'Perfil',
    news: 'Novidades',
    on: 'Ligado',
    off: 'Desligado',
    hited: 'Acertou',
    missed: 'Errou',
    enabledItem: 'Item Habilitado',
    disabledItem: 'Item Desabilitado',
    questionNumber: 'NumeroQuestao',
    canceledOnQuestion: 'Cancelar na questao',
    numberOfParticipatingStudents: 'Numero de alunos participantes',
    totalNumberOfStudents: 'Numero de alunos participantes',
    accessEurekaReport: 'Relatorio do Eureka',
    accessAsStudent: 'Acessar como aluno',
    accessAncestralArena: 'Acessar Arena Ancestral',
    numberOfPage: 'Numero da pagina',
    usedTimer: 'usou temporizador',
    notUsedTimer: 'nao usou temporizador',
    usedRanking: 'selecionou exibir classificação de alunos',
    notUsedRanking: 'nao selecionou exibir classificação de alunos',
    openModalSelectClassroom: 'abriu modal selecionar turma',
    sasQuestions: 'missao com questoes do banco sas educação',
    teacherQuestions: 'missao com questoes autoral',
    hybridQuestions: 'missao com questoes hibridas',
    addSasQuestions: 'adicionar questoes SAS Plataforma de Educação',
    addTeacherQuestions: 'adicionar sua propria questao',
    usedSubjects: 'usou filtro de assuntos',
    notUsedSubjects: 'nao usou filtro de assuntos',
    percentageSasQuestions: 'porcentagem de questoes SAS',
    openSelectClassModal: 'abriu modal selecionar turma',
    selectedClassesQuantity: 'quantidade de turmas selecionadas',
    numberOfStudentsParticipating: 'Número de alunos participantes',
    teacherEndedHomeMission: 'professor finalizou missao para casa',
    teacherViewedLiveMission: 'professor visualizou missao ao vivo',
    teacherViewedHomeMission: 'professor visualizou missao para casa',
    teacherViewedReport: 'professor entrou no relatorio',
    teacherResumedLiveMission: 'professor retomou missao ao vivo',
    report: 'Relatorio',
    watchInMinutes: 'AssistiuEmMinutos',
  },
}

const gradeTemplates = [
  {
    name: 'science',
    templates: [
      {
        gradeId: 13,
        gradeTemplate: 4,
        ground: {
          background: '#9DD3F4',
          color: '#2D1F49',
        },
        sky: {
          background: '#81BDEA',
          color: '#2D1F49',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 2,
        gradeTemplate: 5,
        ground: {
          background: '#E2C5AA',
          color: '#2D1F49',
        },
        sky: {
          background: '#5CBDFF',
          color: '#2D1F49',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 3,
        gradeTemplate: 6,
        ground: {
          background: '#C8D5E3',
        },
        sky: {
          background: '#8AAFCC',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 14,
        gradeTemplate: 7,
        ground: {
          background: '#174B7F',
          color: '#FFFFFF',
        },
        sky: {
          background: '#111E30',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 15,
        gradeTemplate: 8,
        ground: {
          background: '#D3B182',
        },
        sky: {
          background: '#1077F0',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
    ],
  },
  {
    name: 'math',
    templates: [
      {
        gradeId: 8,
        gradeTemplate: 3,
        ground: {
          background: '#BBBBBB',
          color: '#2D1F49',
        },
        sky: {
          background: '#FFFFFF',
          color: '#2D1F49',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 13,
        gradeTemplate: 4,
        ground: {
          background: '#3A8E3D',
          color: '#FFFFFF',
        },
        sky: {
          background: '#1077F0',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 2,
        gradeTemplate: 5,
        ground: {
          background: '#3A8E3D',
          color: '#FFFFFF',
        },
        sky: {
          background: '#1077F0',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 3,
        gradeTemplate: 6,
        ground: {
          background: '#FEDB8E',
        },
        sky: {
          background: '#F9E1B2',
        },
        imageAmount: {
          left: 3,
          right: 3,
        },
      },
      {
        gradeId: 14,
        gradeTemplate: 7,
        ground: {
          background: '#EEE0BE',
          color: '#2D1F49',
        },
        sky: {
          background: '#F9E1B2',
          color: '#2D1F49',
        },
        imageAmount: {
          left: 3,
          right: 3,
        },
      },
      {
        gradeId: 15,
        gradeTemplate: 8,
        ground: {
          background: '#D3B39B',
          color: '#2D1F49',
        },
        sky: {
          background: '#AC6EE4',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 16,
        gradeTemplate: 9,
        ground: {
          background: '#3A8E3D',
          color: '#FFFFFF',
        },
        sky: {
          background: '#5CBDFF',
          color: '#2D1F49',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
    ],
  },
  {
    name: 'portuguese',
    templates: [
      {
        gradeId: 8,
        gradeTemplate: 3,
        ground: {
          background: '#3A8E3D',
          color: '#FFFFFF',
        },
        sky: {
          background: '#31444C',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 13,
        gradeTemplate: 4,
        ground: {
          background: '#FFE3A6',
        },
        sky: {
          background: '#FFB29D',
        },
        imageAmount: {
          left: 4,
          right: 3,
        },
      },
      {
        gradeId: 2,
        gradeTemplate: 5,
        ground: {
          background: '#995D30',
          color: '#FFFFFF',
        },
        sky: {
          background: '#FFB584',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 3,
        gradeTemplate: 6,
        ground: {
          background: '#B7B738',
        },
        sky: {
          background: '#96D7E1',
        },
        imageAmount: {
          left: 4,
          right: 4,
        },
      },
      {
        gradeId: 14,
        gradeTemplate: 7,
        ground: {
          background: '#89CDA5',
        },
        sky: {
          background: '#9B9AC5',
        },
        imageAmount: {
          left: 5,
          right: 5,
        },
      },
      {
        gradeId: 15,
        gradeTemplate: 4,
        ground: {
          background: '#FFE3A6',
        },
        sky: {
          background: '#FFB29D',
        },
        imageAmount: {
          left: 4,
          right: 3,
        },
      },
      {
        gradeId: 16,
        gradeTemplate: 9,
        ground: {
          background: '#174B7F',
          color: '#FFFFFF',
        },
        sky: {
          background: '#284970',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
    ],
  },
  {
    name: 'history',
    templates: [
      {
        gradeId: 13,
        gradeTemplate: 4,
        ground: {
          background: '#90A35B',
        },
        sky: {
          background: '#1077F0',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 2,
        gradeTemplate: 5,
        ground: {
          background: '#1B9AE0',
          color: '#FFFFFF',
        },
        sky: {
          background: '#1077F0',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 3,
        gradeTemplate: 6,
        ground: {
          background: '#89CDA5',
        },
        sky: {
          background: '#9B9AC5',
        },
        imageAmount: {
          left: 5,
          right: 5,
        },
      },
      {
        gradeId: 14,
        gradeTemplate: 7,
        ground: {
          background: '#B7B738',
        },
        sky: {
          background: '#96D7E1',
        },
        imageAmount: {
          left: 4,
          right: 4,
        },
      },
      {
        gradeId: 15,
        gradeTemplate: 8,
        ground: {
          background: '#1B9AE0',
          color: '#FFFFFF',
        },
        sky: {
          background: '#1077F0',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 16,
        gradeTemplate: 9,
        ground: {
          background: '#AF6A58',
          color: '#FFFFFF',
        },
        sky: {
          background: '#01AFF0',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
    ],
  },
  {
    name: 'geography',
    templates: [
      {
        gradeId: 13,
        gradeTemplate: 4,
        ground: {
          background: '#6D5641',
          color: '#FFFFFF',
        },
        sky: {
          background: '#756A4C',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 2,
        gradeTemplate: 5,
        ground: {
          background: '#3A8E3D',
          color: '#FFFFFF',
        },
        sky: {
          background: '#5CBDFF',
          color: '#2D1F49',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 3,
        gradeTemplate: 6,
        ground: {
          background: '#0C81C1',
          color: '#FFFFFF',
        },
        sky: {
          background: '#38A6FA',
        },
        imageAmount: {
          left: 3,
          right: 3,
        },
      },
      {
        gradeId: 14,
        gradeTemplate: 7,
        ground: {
          background: '#3A8E3D',
          color: '#FFFFFF',
        },
        sky: {
          background: '#3780F7',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 15,
        gradeTemplate: 8,
        ground: {
          background: '#3A8E3D',
          color: '#FFFFFF',
        },
        sky: {
          background: '#FDC117',
          color: '#2D1F49',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
      {
        gradeId: 16,
        gradeTemplate: 9,
        ground: {
          background: '#254C4C',
          color: '#FFFFFF',
        },
        sky: {
          background: '#662569',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
    ],
  },
  {
    name: 'biology',
    templates: [
      {
        gradeId: 16,
        gradeTemplate: 9,
        ground: {
          background: '#23B6F4',
          color: '#FFFFFF',
        },
        sky: {
          background: '#01AFF0',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
    ],
  },
  {
    name: 'chemistry',
    templates: [
      {
        gradeId: 16,
        gradeTemplate: 9,
        ground: {
          background: '#112D2C',
          color: '#FFFFFF',
        },
        sky: {
          background: '#193D3E',
          color: '#FFFFFF',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
    ],
  },
  {
    name: 'physics',
    templates: [
      {
        gradeId: 16,
        gradeTemplate: 9,
        ground: {
          background: '#BBBBBB',
          color: '#2D1F49',
        },
        sky: {
          background: '#01AFF0',
          color: '#2D1F49',
        },
        imageAmount: {
          left: 2,
          right: 2,
        },
      },
    ],
  },
]

export {
  gradeTemplates,
}

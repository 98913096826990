import store from '@/store'
import accountApi from '@/service/account'
import { PROFILES } from '@/service/profiles'

export default async function isTeacherOrManager() {
  const { isTeacher: userIsTeacher, isManager: userIsManager } = store.state.account.user?.data
    ?? { isTeacher: false, isManager: false }

  if (userIsTeacher || userIsManager) {
    return true
  }

  try {
    const { data: personalData } = await accountApi.getPersonalData()

    return personalData.profileId === PROFILES.TEACHER
      || personalData.profileId === PROFILES.MANAGER
  } catch (error) {
    return false
  }
}

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  allNews: {
    loading: false,
    data: [],
    error: null,
  },
  novelty: {
    loading: false,
    data: {},
    error: null,
  },
  unreadNews: {
    loading: false,
    data: {},
    error: null,
  },
  isToOpenNewsDrawer: false,
}

export default {
  state,
  getters,
  mutations,
  actions,
}

import isStudent from '@/utils/isStudent'

const AncestralArenaStudentList = import('@/views/AncestralArena/AncestralArenaList/AncestralArenaStudentList')
const AncestralArenaTeacherList = import('@/views/AncestralArena/AncestralArenaList/AncestralArenaTeacherList')

const AncestralArenaList = async () => {
  const userIsStudent = await isStudent()

  return userIsStudent ? AncestralArenaStudentList : AncestralArenaTeacherList
}

export default AncestralArenaList

import { done } from '@/store/utils'

export default {
  historySeasons: (state) => ({
    ...state.seasons,
    done: done(state.seasons),
  }),
  historyEpisode: (state) => ({
    ...state.historyEpisode,
  }),
  currentEpisodeScene: (state) => ({
    ...state.episodeScene,
  }),
}

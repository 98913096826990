import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  soundPreferences: {
    data: {},
  },
  allPreferences: {
    loading: true,
    data: null,
    error: null,
    ready: false,
  },
  scenesAlreadyPlayedEffects: {
    data: [],
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

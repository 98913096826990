import load from '@/utils/asyncResourceLoader'

const themes = {
  1153: () => import('./science'),
  1129: () => import('./portuguese'),
  972: () => import('./math'),
  1110: () => import('./physics'),
  1111: () => import('./chemistry'),
  1123: () => import('./biology'),
  1115: () => import('./geography'),
  1114: () => import('./history'),
}

const themedLectures = Object.keys(themes).map(Number)

export {
  themedLectures,
}

export default load(themes)

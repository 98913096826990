import storage from '@/utils/storage'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { TOUR_VERSION } from './utils/constants'

const onboarding = {
  users: {},
}

const state = {
  onboarding: storage.getItem(TOUR_VERSION) || onboarding,
}

export default {
  state,
  getters,
  mutations,
  actions,
}

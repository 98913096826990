const initialState = Object.freeze({
  isOpen: false,
  text: '',
  description: '',
  asset: '',
  variation: 'success',
  autoClose: 5000,
})

export default initialState

import Vue from 'vue'
import i18n from '@/i18n'
import App from './App'
import router from './router'
import store from './store'
import './plugins'
import '@sas-te/alfabeto-vue/dist/alfabeto.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#eurekaapp')

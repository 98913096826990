import axios from 'axios'

import httpCodes from '@/enums/httpCodes'
import { getCookie, setCookie, deleteCookie } from '@sas-te/frontend-utils/modules/cookies'
import urls, { getPortalURL } from './urls'
import { cookieDomain } from './domains'

const tokenCookieName = 'token'
const refreshTokenCookieName = 'refreshToken'
const accessTokenCookieName = 'accessToken'
const sessionCookieName = 'session'
const authServerAPI = urls.AUTH_API_V2
const cookiesConfig = { domain: cookieDomain }
const isMicrofrontend = sessionStorage.getItem('isMicrofrontend')

export const getAuthToken = () => getCookie(tokenCookieName)
export const getAuthSession = () => getCookie(sessionCookieName)
export const getRefreshToken = () => getCookie(refreshTokenCookieName)
export const getAccessToken = () => sessionStorage.getItem(accessTokenCookieName)
export const hasRefreshToken = () => Boolean(getRefreshToken())
export const hasAuthToken = () => Boolean(getAuthToken())
export const isAuthenticated = () => hasAuthToken() || hasRefreshToken()

export const setAuthCookies = ({
  token, refreshToken, sessionToken, accessToken,
}) => {
  const THIRTY_DAYS_IN_SECONDS = 2592000
  const bearerToken = token.startsWith('Bearer') ? token : `Bearer ${token}`

  setCookie(tokenCookieName, bearerToken, cookiesConfig)
  setCookie('strategy', 'token', cookiesConfig)
  setCookie(sessionCookieName, sessionToken, cookiesConfig)
  setCookie(accessTokenCookieName, accessToken, {
    'max-age': THIRTY_DAYS_IN_SECONDS,
    domain: cookieDomain,
  })
  setCookie(refreshTokenCookieName, refreshToken, {
    'max-age': THIRTY_DAYS_IN_SECONDS,
    domain: cookieDomain,
  })
}

export const clearAuthTokens = () => {
  deleteCookie(tokenCookieName, cookiesConfig)
  deleteCookie('strategy', cookiesConfig)
  deleteCookie(sessionCookieName, cookiesConfig)
  deleteCookie(refreshTokenCookieName, cookiesConfig)
}

export const logout = async ({ redirectBack } = { redirectBack: false }) => {
  const redirectToLogin = () => {
    const portalURL = getPortalURL(isMicrofrontend)
    const urlFormated = isMicrofrontend ? `${portalURL}/entrar` : `${portalURL}/login`
    const redirectPortalParam = isMicrofrontend ? 'redirectTo' : 'redirect'
    const { href } = window.location
    const normalizedURL = (
      href.endsWith('/')
        ? href.substr(0, href.length - 1) : href
    ).split('?')[0]

    let params = ''

    if (redirectBack && normalizedURL !== portalURL && normalizedURL !== urlFormated) {
      params += `?${redirectPortalParam}=${encodeURIComponent(href)}`
    }

    window.location.assign(`${portalURL}${params}`)
  }

  await axios.post(`${authServerAPI}/auth/logout`, null, { withCredentials: true })

  clearAuthTokens()
  redirectToLogin()
}

export const refreshAuthTokens = (() => {
  let refreshTokenPromise = null

  return async () => {
    // Prevent refreshToken to be called multiple times
    if (refreshTokenPromise instanceof Promise) {
      return refreshTokenPromise
    }

    refreshTokenPromise = axios.post(
      `${urls.AUTH_API_V2}/auth/refreshToken`, {
        token: getRefreshToken(),
      }
    )

    const response = await refreshTokenPromise

    setAuthCookies(response.data.authResponse)

    // clear promise
    refreshTokenPromise = null

    return Promise.resolve(response)
  }
})()

export function addAuthorizationHeader(config) {
  const requestConfig = { ...config }

  requestConfig.headers.Authorization = getAuthToken()

  return requestConfig
}

export function addXCrossTokenHeader(config, token = getAccessToken()) {
  const requestConfig = { ...config }

  requestConfig.headers['x-cross-token'] = token

  return requestConfig
}

export async function handleResponseInterceptorError(error) {
  const handleUnauthorizedRequest = (status) => {
    if (status === httpCodes.unauthorized) {
      logout({ redirectBack: true })
    }
  }

  if (error.response) {
    if (error.response.status === httpCodes.unauthorized && hasRefreshToken()) {
      try {
        await refreshAuthTokens()

        return axios(addAuthorizationHeader(error.config))
      } catch (newError) {
        handleUnauthorizedRequest(newError.response.status)
      }
    } else {
      handleUnauthorizedRequest(error.response.status)
    }
  }

  return Promise.reject(error)
}

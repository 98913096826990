import Vue from 'vue'
import Vuex from 'vuex'

import account from '@/store/account'
import arena from '@/store/arena'
import assignments from '@/store/assignments'
import error from '@/store/error'
import featureToggles from '@/store/featureToggles'
import grades from '@/store/grades'
import history from '@/store/history'
import items from '@/store/items'
import lectures from '@/store/lectures'
import logos from '@/store/logos'
import news from '@/store/news'
import onboarding from '@/store/onboarding'
import profileTabs from '@/store/profileTabs'
import questions from '@/store/questions'
import theme from '@/store/theme'
import typeform from '@/store/typeform'
import userPreferences from '@/store/userPreferences'
import globalToast from '@/store/global-toast'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    arena,
    assignments,
    error,
    featureToggles,
    globalToast,
    grades,
    history,
    items,
    lectures,
    news,
    onboarding,
    profileTabs,
    questions,
    theme,
    typeform,
    logos,
    userPreferences,
  },
  strict: process.env.VUE_APP_MODE !== 'production',
})

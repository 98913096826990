import api from '@/service/api'
import * as types from './types'
import { getContentYear } from '../utils'

export default {
  getAssignments: async ({ commit, rootState }, { lectureId, gradeId }) => {
    const { grades } = rootState.account.user.data
    const { contractType } = rootState.account.user.data.grades[0]
    const currentContentYear = rootState.account.user.data.contentYear

    const contentYear = getContentYear(
      currentContentYear,
      contractType,
      grades,
      gradeId
    )

    const params = {
      contentYear,
    }

    if (gradeId) {
      params.gradeId = gradeId
    }

    commit(types.SET_ASSIGNMENTS, { loading: true, data: null, error: null })

    return api.get(`/game/lectures/${lectureId}/assignments`, { params })
      .then((response) => {
        commit(types.SET_ASSIGNMENTS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_ASSIGNMENTS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  setAssignment: ({ commit }, assignment) => {
    commit(types.SET_ASSIGNMENT, {
      data: assignment,
      loading: false,
      error: null,
    })
  },
  getReport: ({ commit }, questionnaireCode) => {
    commit(types.SET_REPORT, {
      data: null,
      loading: true,
      error: null,
    })

    return api.get(`/questionnaires/${questionnaireCode}/reports`)
      .then((response) => {
        commit(types.SET_REPORT, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_REPORT, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  redoAssignment: ({ commit }, { assignmentCode }) => {
    commit(types.SET_REDO, { loading: true })

    return api.post(`/assignments/${assignmentCode}/redo`)
      .then(() => {
        commit(types.SET_REDO, { loading: false })
      })
      .catch((error) => {
        commit(types.SET_REDO, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
}

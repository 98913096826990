import Vue from 'vue'

// TODO: Analisar possibilidade de desabilitar otimização SSR global apenas em ambiente de tests
async function dynamicLoadVueHorizontal(env) {
  if (env === 'test') {
    Vue.config.ssrRender = false
    const { default: VueHorizontal } = await import('vue-horizontal/dist/vue-horizontal.esm')
    Vue.use(VueHorizontal)
  } else {
    const { default: VueHorizontal } = await import('vue-horizontal')
    Vue.use(VueHorizontal)
  }
}

dynamicLoadVueHorizontal(process.env.NODE_ENV)

import api from '@/service/api'
import { apiBff } from '@/service/apiBff'
import * as types from './types'

export default {
  getShopItems: ({ commit }, { gradeId }) => {
    commit(types.SET_SHOP_ITEMS, {
      data: null,
      loading: true,
      error: null,
    })

    const params = {}

    if (gradeId) {
      params.gradeId = gradeId
    }

    return apiBff.get('/shop-items', { params })
      .then((response) => {
        commit(types.SET_SHOP_ITEMS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_SHOP_ITEMS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  getBagItems: async ({ commit }, params = {}) => {
    commit(types.SET_BAG_ITEMS, {
      loading: true,
      error: null,
    })
    commit(types.SET_USED_ITEMS, {
      loading: true,
      error: null,
    })
    try {
      const response = await apiBff.get('bag', { params })
      commit(types.SET_BAG_ITEMS, { data: response.data.bagItems })
      commit(types.SET_USED_ITEMS, { data: response.data.usedItems })
    } catch (error) {
      commit(types.SET_BAG_ITEMS, { error: error.response || true })
      commit(types.SET_USED_ITEMS, { error: error.response || true })
    } finally {
      commit(types.SET_BAG_ITEMS, { loading: false })
      commit(types.SET_USED_ITEMS, { loading: false })
    }
  },
  resetBagItems: ({ commit }) => {
    commit(types.SET_BAG_ITEMS, { data: null })
    commit(types.SET_USED_ITEMS, { data: null })
  },
  useItem: ({ commit }, usedItem) => {
    commit(types.REMOVE_BAG_ITEM, usedItem)
    commit(types.ADD_USED_ITEM, usedItem)
  },
  getItemEffects: (
    { commit, rootState },
    {
      id,
      assignmentCode,
      questionId,
      gradeId,
    }
  ) => {
    commit(types.SET_ITEM_EFFECTS, { loading: true })

    const body = { assignmentCode, questionId }

    if (gradeId) {
      body.gradeId = gradeId
    }

    return api.post(`/usage-items/${id}`, body)
      .then((response) => {
        const rootItemEffects = rootState.items.itemEffects.data
        const alreadyUsed = rootItemEffects.some(
          ({ type }) => type === rootItemEffects.type
        )

        if (!alreadyUsed) {
          commit(types.SET_ITEM_EFFECTS, {
            data: [ response.data.itemResponse ],
          })
        }
      })
      .catch((error) => {
        const isUnprocessableEntityError = error.response.status === 422

        commit(types.SET_ITEM_EFFECTS, {
          error: isUnprocessableEntityError ? error.response.data : true,
        })

        return Promise.reject(error.response)
      })
      .finally(() => {
        commit(types.SET_ITEM_EFFECTS, {
          loading: false,
        })
      })
  },
  resetEffects: async ({ commit }) => {
    commit(types.CLEAR_ITEM_EFFECTS)
  },
}

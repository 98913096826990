import { reduceImage } from '@/utils/image'
import api from './api'
import { apiBff } from './apiBff'

const fetchCurrentQuestion = (questionnaireCode) => api.get(
  `/questionnaires/${questionnaireCode}/assignments`
)

const fetchNextQuestion = (assignmentCode) => api.get(
  `/assignments/${assignmentCode}/questions`
)

export default {
  fetchProgress: async (assignmentCode) => api.get(
    `/assignments/${assignmentCode}/progress`
  ),
  fetchQuestion: ({
    questionnaireCode,
    assignmentCode,
  }) => {
    if (questionnaireCode) {
      return fetchCurrentQuestion(questionnaireCode)
    }
    if (assignmentCode) {
      return fetchNextQuestion(assignmentCode)
    }

    return Promise.reject()
  },
  fetchPageFlips: async (questionnaireCode) => {
    const response = await api.get(
      `questionnaires/${questionnaireCode}/pageflips`
    )

    return response.data
  },
  fetchVideo: (questionnaireCode) => (
    api.get(`/questionnaires/${questionnaireCode}/videos`)
  ),
  postAnswer: async ({ assignmentCode, answerBody }) => {
    const response = await api.post(
      `/assignments/${assignmentCode}/answers`, answerBody
    )

    return response.data
  },
  async uploadImage(imageURL, config) {
    const formData = new FormData()
    let imageBlob

    try {
      imageBlob = await reduceImage({
        src: imageURL,
        maxSize: 500,
      })
    } catch (error) {
      throw new Error('Erro ao minificar imagem')
    }

    formData.append('file', imageBlob)

    try {
      const response = await apiBff.post(
        'images/upload',
        formData,
        {
          header: {
            'Content-Type': 'multipart/form-data',
          },
          ...config,
        },
      )

      return response
    } catch (error) {
      if (error.response?.status) {
        throw new Error(`Erro ao salvar imagem. Código: ${error.response?.status}`)
      }

      throw error
    }
  },
}

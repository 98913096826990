import { success, done } from '@/store/utils'

export default {
  lectures: (state) => ({
    ...state.lectures,
    success: success(state.lectures),
    done: done(state.lectures),
  }),
  lecture: (state) => ({
    ...state.lecture,
    success: success(state.lecture),
    done: done(state.lecture),
  }),
  nextLecture: (state) => ({
    ...state.nextLecture,
  }),
}

import api from '@/service/api'
import * as types from './types'

export default {
  setCurrentGrade: ({ commit }, currentGrade) => {
    commit(types.SET_CURRENT_GRADE, {
      data: currentGrade,
      loading: false,
    })
  },
  getClassrooms: ({ commit }) => {
    commit(types.SET_CLASSROOMS, { loading: true })

    return api.get('game/classrooms')
      .then((response) => {
        commit(types.SET_CLASSROOMS, {
          data: response.data,
          loading: false,
        })
      })
      .catch((error) => {
        commit(types.SET_CLASSROOMS, {
          error: error.response || true,
          loading: false,
        })

        return Promise.reject(error)
      })
  },
  setActiveClassroom: ({ commit }, classroom) => {
    commit(types.SET_ACTIVE_CLASSROOM, { data: classroom })
  },
}
